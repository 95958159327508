<template>
  <img
    data-dismiss="modal"
    v-if="producto.logo"
    :src="`https://${restaurante.path}/uploads/images/productos/${restaurante.eid}/${restaurante.id}/${producto.logo}`"
    alt="Imagen"
    class="img-fluid img-descripcion"
  />
  <div class="no-fotografia" v-else>
    <div
      data-bs-toggle="collapse"
      data-bs-target="#agregar-fotografia"
      class="agregar-foto"
    >
      Colabora subiendo una foto
      <i class="ms-2 bi bi-upload"></i>
    </div>
    <img
      src="@/assets/images/no-imagen-producto.jpg"
      alt="Imagen"
      class="ng-scope img-fluid img-descripcion"
    />
  </div>

  <div class="collapse agregar-fotografia" id="agregar-fotografia">
    <p>
      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed fugit, error
      minus sequi provident aliquid voluptas! Atque, ea modi adipisci nulla amet
      eveniet doloremque sint maiores laborum earum officiis sequi?
    </p>

    <form>
      <div class="form-group">
        <!-- <label for="fotografia-nombre">Nombre</label> -->
        <input
          type="email"
          class="form-control mb-3"
          id="fotografia-nombre"
          placeholder="Introduce tu nombre"
        />
      </div>
      <div class="form-group">
        <!-- <label for="exampleFormControlFile1">Subir fotografía</label> -->
        <input
          type="file"
          class="form-control mb-3"
          id="exampleFormControlFile1"
        />
      </div>

      <button type="submit" class="btn w-100 btn-verde">
        Enviar Fotografía
      </button>
    </form>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  props: ["producto"],

  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);

    return { restaurante };
  },
};
</script>

<style lang="scss" scoped>
.agregar-foto {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: #46b975;
  text-align: center;
  padding: 15px;
  font-weight: 700;
  bottom: 0;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

button {
  background-color: #4caf50 !important;
  color: #fff;
  font-weight: bold;
  color: 16px;
  padding: 15px 0;
}

#agregar-fotografia {
  padding: 25px 25px 75px;
}
</style>