<template>
  <Layout>
    <div class="container mobile-m mt-5 mb-5">
      <div class="row">
        <div class="col-md-12">
          <h1 class="">Reservas</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
            ratione reiciendis nostrum deleniti expedita eos, necessitatibus
            doloribus beatae accusantium voluptatibus ad perferendis consequatur
            repellendus soluta reprehenderit repellat non obcaecati ipsa!
          </p>
        </div>
        <div class="col-md-5 my-3 my-sm-0">
          <Datepicker
            v-model="date"
            :minDate="new Date()"
            inline
            hideOffsetDates
            minutesIncrement="10"
            noHoursOverlay
            noMinutesOverlay
            :autoPosition="false"
            :startTime="startTime"
            locale="es"
            selectText="seleccionar"
            :time-picker-component="timePicker"
          />
        </div>
        <div class="col-md-7 my-5 my-sm-0">
          <form class="mb-5 p-3 p-sm-5 shadow bg-form">
            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="inputEmail4" class="form-label">Nombre</label>
                <input type="text" class="form-control" id="inputEmail4" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputEmail4" class="form-label"
                  >Numero de personas</label
                >
                <select class="form-select" aria-label="Default select example">
                  <option selected>Seleccionar numero de personas</option>
                  <option value="1">1 Personas</option>
                  <option value="2">2 Personas</option>
                  <option value="3">3 Personas</option>
                  <option value="4">4 Personas</option>
                  <option value="5">5 Personas</option>
                  <option value="6">6 Personas</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputPassword4" class="form-label">Telefono</label>
                <input type="number" class="form-control" id="inputPassword4" />
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputPassword4" class="form-label">Email</label>
                <input type="email" class="form-control" id="inputPassword4" />
              </div>
              <div class="col-12 mt-2 mb-3">
                <label for="exampleFormControlTextarea1" class="form-label"
                  >Petición
                </label>
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
              </div>
              <div class="col-12">
                <button type="submit" class="btn btn-verde w-100">
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";

import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { computed, ref } from "@vue/reactivity";
import { defineAsyncComponent, onMounted } from "@vue/runtime-core";

export default {
  components: {
    Layout,
    Datepicker,
  },

  setup() {
    const date = ref(new Date());
    const startTime = ref({ hours: 0, minutes: 0 });

    // Lazy load the component we want to pass
    const TimePicker = defineAsyncComponent(() =>
      import("../components/horarios/Horario.vue")
    );

    // Return from computed as it is imported
    const timePicker = computed(() => TimePicker);

    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      date.value = [startDate, endDate];
    });

    return {
      date,
      startTime,
      timePicker,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-form {
  padding: 25px;
  background: #f3f6f9;
  border-radius: 8px;
  border: 1px solid #ebeff3;
}

.dp__main {
  width: 100%;
  z-index: 0;
  display: block;
}

div.container.mobile-m.mt-5.mb-5
  > div
  > div.col-md-5.my-3.my-sm-0
  > div
  > div.dp__menu.dp__relative.dp__theme_light
  > div.dp__action_row
  > div.dp__action_buttons
  > span {
  width: 100% !important;
}
</style>