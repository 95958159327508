<template>
  <div class="contenedor-social" v-if="producto.logo">
    <div class="social">
      <div @click="compartir()" class="compartir">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512.001 512.001"
          style="enable-background: new 0 0 512.001 512.001"
          xml:space="preserve"
        >
          <g>
            <path
              style="fill: #231f20"
              d="M350.952,365.555l-116.654-70.641c9.257-25.04,9.263-52.77,0-77.827l116.654-70.641
        			c54.036,52.677,144.892,14.024,144.892-61.088C495.845,38.291,457.555,0,410.486,0c-56.883,0-98.202,55.029-81.763,109.836
        			L212.066,180.48c-20.605-22.727-50.347-37.023-83.367-37.023c-62.056,0-112.543,50.486-112.543,112.543
        			s50.486,112.543,112.543,112.543c33.02,0,62.762-14.297,83.367-37.022l116.657,70.644
        			c-16.415,54.729,24.798,109.836,81.763,109.836c47.067,0,85.359-38.291,85.359-85.358
        			C495.845,351.415,404.927,312.94,350.952,365.555z M410.486,42.829c23.451,0,42.53,19.078,42.53,42.528s-19.078,42.53-42.53,42.53
        			c-23.451,0-42.528-19.078-42.528-42.53C367.958,61.907,387.037,42.829,410.486,42.829z M128.697,325.712
        			c-38.439,0-69.713-31.273-69.713-69.713c0-38.441,31.273-69.713,69.713-69.713c38.441,0,69.712,31.273,69.712,69.713
        			C198.409,294.439,167.138,325.712,128.697,325.712z M410.486,469.171c-23.451,0-42.528-19.078-42.528-42.528
        			c0-23.451,19.078-42.53,42.528-42.53c23.451,0,42.53,19.078,42.53,42.53C453.016,450.092,433.938,469.171,410.486,469.171z"
            ></path>
          </g>
        </svg>
      </div>

      <div class="puntuar" @click="puntuar()">
        <svg
          id="fe86c9a7-3a93-470a-95bd-e6acca269a0f"
          data-name="Capa 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 278.73 266.12"
        >
          <polyline
            points="21.73 105.88 101.32 90.69 139.36 17.39 177.27 83.76 186.39 95.55 262.94 105.88 203.4 169.06 217.98 253.52 136.56 213.42 61.22 253.52 70.94 166.03 12.61 103.44"
            style="fill: #ffeb3b; stroke: #000; stroke-miterlimit: 10"
          />
          <path
            d="M284.1,101.54l-83.6-12L162.89,13.77a10.45,10.45,0,0,0-18.81,0L106.46,89.52l-83.59,12a10.44,10.44,0,0,0-8.36,7.31,11,11,0,0,0,2.61,10.45l60.61,59L63.62,261.41a11,11,0,0,0,4.18,10.45,10.45,10.45,0,0,0,11,1l74.71-39.7,74.71,39.7L232.9,274a8.35,8.35,0,0,0,6.26-2.09,11,11,0,0,0,4.18-10.45l-14.1-83.07,60.6-59a11,11,0,0,0,2.61-10.45A10.44,10.44,0,0,0,284.1,101.54Zm-73.67,65.83a11,11,0,0,0-2.61,8.88l11.49,67.92L158.19,212.3a11,11,0,0,0-9.41,0L87.65,244.17l11.5-67.92a11,11,0,0,0-2.61-8.88L46.9,119.3l68.44-9.92a11,11,0,0,0,7.84-5.75L153.48,42l30.31,61.65a11,11,0,0,0,7.83,5.75l68.44,9.92Z"
            transform="translate(-14.12 -7.87)"
          />
        </svg>
      </div>
      <div
        :class="[activo && 'activo']"
        @click="agregarAListaDeseos(producto)"
        class="favoritos"
      ></div>
    </div>

    <div
      :class="[opciones.puntuar && 'mostrar']"
      class="modal-producto puntuar"
    >
      <p class="fw-bold text-center mb-0">Puntuar fotografia</p>
      <!-- <div class="rating-mensaje">Puntuación recibida. Gracias :)</div> -->
      <div class="row justify-content-center">
        <div class="rating">
          <input
            type="radio"
            :id="`star5-${producto.id}`"
            name="rating"
            value="5"
          /><label :for="`star5-${producto.id}`">5 stars</label>
          <input
            type="radio"
            :id="`star4-${producto.id}`"
            name="rating"
            value="4"
          /><label :for="`star4-${producto.id}`">4 stars</label>
          <input
            type="radio"
            :id="`star3-${producto.id}`"
            name="rating"
            value="3"
          /><label :for="`star3-${producto.id}`">3 stars</label>
          <input
            type="radio"
            :id="`star2-${producto.id}`"
            name="rating"
            value="2"
          /><label :for="`star2-${producto.id}`">2 stars</label>
          <input
            type="radio"
            :id="`star1-${producto.id}`"
            name="rating"
            value="1"
          /><label :for="`star1-${producto.id}`">1 star</label>
        </div>
      </div>
    </div>

    <div
      class="modal-producto compartir"
      :class="[opciones.social && 'mostrar']"
    >
      <p class="fw-bold text-center mb-0">Compartir Foto</p>

      <ul>
        <li>
          <a href="#">
            <img src="@/assets/images/facebook.svg" alt="" />
          </a>
        </li>

        <li>
          <a href="#">
            <img src="@/assets/images/whatsapp.svg" alt="" />
          </a>
        </li>

        <li>
          <a href="#">
            <img src="@/assets/images/instagram.svg" alt="" />
          </a>
        </li>

        <li>
          <a href="#">
            <img src="@/assets/images/linkedin.svg" alt="" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, inject, reactive } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  props: ["producto"],

  setup(props) {
    const store = useStore();
    const dialog = inject("$swal");
    const opciones = reactive({
      puntuar: false,
      puntuarCompletado: false,
      social: false,
    });

    const activo = computed(() =>
      store.getters["productos/obtenerActivo"](props.producto.id)
    );

    const puntuar = () => {
      console.log("wew?");
      opciones.puntuar = !opciones.puntuar;
    };

    const compartir = () => {
      opciones.social = !opciones.social;
    };

    const agregarAListaDeseos = (producto) => {
      store.dispatch("productos/agregarAlistaDeDeseos", producto);

      //    dialog({
      //   position: "bottom-right",
      //   toast: true,
      //   showConfirmButton: false,
      //   icon: "error",
      //   title: "Debes rellenar todas las preguntas",
      //   timer: 2000,
      // });
    };

    return {
      agregarAListaDeseos,
      puntuar,
      compartir,
      activo,
      opciones,
    };
  },
};
</script>

<style lang="scss" scoped>
.contenedor-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
.social {
  position: absolute;
  display: flex;
  bottom: 15px;
  right: 15px;
  align-items: center;
  z-index: 100;

  div {
    border-radius: 50px;
    cursor: pointer;
    background-color: #fff;
    width: 32px;
    height: 32px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .favoritos {
    width: 42px;
    height: 42px;
    background-image: url("../../assets/images/corazon_degradado.png");
    background-repeat: no-repeat;
    background-position: 3px 4px;
    background-color: transparent;
    box-shadow: none;

    .favorito {
      fill: #fff;
      transform: scale(0) translate(-1.08 -3.58);
      transition: 0.2s ease-in-out;
      transform-origin: center center;
    }

    &.activo {
      animation: zoomOn 0.424s ease-in-out;
      background-image: url("../../assets/images/corazon_hover.png");
      .favorito {
        fill: #ff5722;
        transform: scale(1) translate(-1.08 -3.58);
      }
    }

    @keyframes zoomOn {
      0% {
        transform: scale(0);
      }

      50% {
        transform: scale(1.5);
      }

      100% {
        transform: scale(1);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.modal-producto {
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%) scale(0);
  width: 200px;
  border-radius: 8px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
  transition: 0.3s ease-in-out;
  opacity: 0;

  @media (max-width: 698px) {
    padding: 8px;
  }

  //Estilos Compartir
  &.compartir {
    // display: none;
    text-align: center;
    overflow: hidden;

    p {
      font-size: 12px;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      padding: 0;
      margin: 0 0 8px 0;
      margin: 0;
      opacity: 0;

      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 28px;
      height: 28px;
      box-shadow: none;
      border-radius: 35%;
      // @media (max-width: 698px) {

      //   margin-right: 4px;
      // }
    }

    a {
      @media (max-width: 698px) {
        font-size: 12px;
      }
    }

    &.mostrar {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      z-index: 100;
      li {
        animation-name: fadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: both;

        &:nth-child(1) {
          animation-delay: 0.2s;
        }
        &:nth-child(2) {
          animation-delay: 0.4s;
        }

        &:nth-child(3) {
          animation-delay: 0.6s;
        }
        &:nth-child(4) {
          animation-delay: 0.8s;
        }
      }
    }
  }

  //Estilos puntuar
  &.puntuar {
    // display: none;

    &.mostrar {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      overflow: hidden;
      .rating {
        animation-name: fadeInUp;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        animation-delay: 0.2s;
      }
    }
  }
}

@keyframes burst {
  0%,
  10% {
    transform: scale(1);
    opacity: 0.5;
    color: lavender;
  }
  45% {
    transform: scale(0.2) rotate(30deg);
    opacity: 0.75;
  }
  50% {
    transform: scale(2) rotate(-37.5deg);
    opacity: 1;
    color: red;
    text-shadow: 2px 2px 6px rgba(235, 9, 9, 0.5);
  }
  90%,
  95% {
    transform: scale(1) rotate(10deg);
    text-shadow: none;
  }
  100% {
    transform: rotate(-2.5deg);
  }
}

.star {
  position: relative;

  display: inline-block;
  width: 0;
  height: 0;

  margin-left: 0.9em;
  margin-right: 0.9em;
  margin-bottom: 1.2em;

  border-right: 0.3em solid transparent;
  border-bottom: 0.7em solid #fc0;
  border-left: 0.3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 24px;

  &:before,
  &:after {
    content: "";

    display: block;
    width: 0;
    height: 0;

    position: absolute;
    top: 0.6em;
    left: -1em;

    border-right: 1em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 1em solid transparent;

    transform: rotate(-35deg);
  }

  &:after {
    transform: rotate(35deg);
  }
}

.rating:not(:checked) > input {
  position: absolute;
  // left: -9999px;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 26px;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: #ffc107;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffc107;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
</style>