<template>
  <div class="lista-comentarios">
    <div class="comentario">
      <div class="estrella">
        <img src="@/assets/images/estrella-comentario.svg" alt="" />
        5
      </div>

      <h3>Jose Eduardo Ramirez</h3>
      <span class="fecha">2 SEP, 2021</span>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint, nulla!
        Non sequi natus voluptatibus
      </p>
    </div>
  </div>
  <!-- Formulario de envio de comentario -->
  <div class="comentario-form">
    <p>¿Deasea agregar un comentario?</p>
    <form>
      <div class="form-row">
        <div class="form-group col-md-12 mb-3">
          <input
            type="text"
            class="form-control"
            id="nombre-${producto.id}"
            placeholder="Tu Nombre"
          />
        </div>
        <div class="form-group col-md-12 mb-3">
          <input
            type="text"
            class="form-control"
            id="movil-${producto.id}"
            placeholder="Móvil (Opcional)"
          />
        </div>

        <div class="form-group col-md-12 mb-3">
          <p class="text-left mb-0 mr-4 d-inline me-4">Agrega una puntuación</p>

          <div class="rating comentario-estrellas">
            <input
              type="radio"
              :id="`comentario-star5-${producto.id}`"
              name="rating-comentario"
              value="5"
            /><label :for="`comentario-star5-${producto.id}`">5 stars</label>
            <input
              type="radio"
              :id="`comentario-star4-${producto.id}`"
              name="rating-comentario"
              value="4"
            /><label :for="`comentario-star4-${producto.id}`">4 stars</label>
            <input
              type="radio"
              :id="`comentario-star3-${producto.id}`"
              name="rating-comentario"
              value="3"
            /><label :for="`comentario-star3-${producto.id}`">3 stars</label>
            <input
              type="radio"
              :id="`comentario-star2-${producto.id}`"
              name="rating-comentario"
              value="2"
            /><label :for="`comentario-star2-${producto.id}`">2 stars</label>
            <input
              type="radio"
              :id="`comentario-star1-${producto.id}`"
              name="rating-comentario"
              value="1"
            /><label :for="`comentario-star1-${producto.id}`">1 star</label>
          </div>
        </div>
      </div>
      <textarea
        class="form-control"
        placeholder="Escribe tu comentario..."
        id="mensaje-comentario-${producto.id}"
        rows="3"
      ></textarea>
      <button type="submit" class="btn btn-primary">Enviar Comentario</button>
    </form>
  </div>
</template>

<script>
export default {
  props: ["producto"],
};
</script>

<style lang="scss" scoped>
.lista-comentarios {
  margin-bottom: 30px;
}
.comentarios {
  .comentario-estrellas {
    display: inline-block;
    height: 27px;
  }
  .comentario {
    background: #f3f6f9;
    padding: 25px;
    border-radius: 16px;
    margin-bottom: 10px;
    position: relative;
    box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.07);

    .estrella {
      position: absolute;
      top: 25px;
      right: 25px;
      font-weight: bold;
    }

    h3 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    .fecha {
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  .comentario-form {
    margin-bottom: 25px;

    .btn {
      width: 100%;
      margin-top: 16px;
      padding: 10px 0;
    }

    p {
      font-weight: bold;
      text-align: center;
    }
  }

  .star {
    position: relative;

    display: inline-block;
    width: 0;
    height: 0;

    margin-left: 0.9em;
    margin-right: 0.9em;
    margin-bottom: 1.2em;

    border-right: 0.3em solid transparent;
    border-bottom: 0.7em solid #fc0;
    border-left: 0.3em solid transparent;

    /* Controlls the size of the stars. */
    font-size: 24px;

    &:before,
    &:after {
      content: "";

      display: block;
      width: 0;
      height: 0;

      position: absolute;
      top: 0.6em;
      left: -1em;

      border-right: 1em solid transparent;
      border-bottom: 0.7em solid #fc0;
      border-left: 1em solid transparent;

      transform: rotate(-35deg);
    }

    &:after {
      transform: rotate(35deg);
    }
  }
}

.rating:not(:checked) > input {
  position: absolute;
  // left: -9999px;
  visibility: hidden;
  clip: rect(0, 0, 0, 0);
}

.rating:not(:checked) > label {
  float: right;
  width: 1em;
  /* padding:0 .1em; */
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 26px;
  /* line-height:1.2; */
  color: #ddd;
}

.rating:not(:checked) > label:before {
  content: "★ ";
}

.rating > input:checked ~ label {
  color: #ffc107;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ffc107;
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #ffc107;
}

.rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}
</style>