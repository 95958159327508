import { API_URL } from "../../utils/constants";

export const restaurante = {
  namespaced: true,
  state: {},
  actions: {
    async fetchInfoRestaurante({ commit }, payload) {
      let params = payload.payload;      

      try {
        //LLamada a multiples enpoints para obtener valores
        let [res1, res2] = await Promise.all([
          fetch(`${API_URL}/getSucursalByName?sucursal=${params.sucursal}`),
          fetch(`${API_URL}/getPuestos?sucursal=${params.sucursal}`)
        ]);

        // Obtener respuesta de las llamadas al api para configurar el state
        let opciones = await res1.json();
        let puestos = await res2.json();
        //Buscar mesa en la llamada del api, si el id es igual al de la mesa, devolvera el numero de mesa, si no es ocurre error.
        let findMesa = params.mesa == 1 || puestos.puestos.find((mesa) => mesa.id == params.mesa);
        if (params.mesa == 1)
            findMesa = puestos.puestos[0];

        // Si la busqueda es correcta
        if (findMesa) {
     
          //Cambiar al simbolo de moneda: 0: Arg 1: USD 3:EUR 
          let moneda;

          switch (opciones.Sucursal.moneda) {
            case 0:
              moneda = "$";
              break;
            case 1:
              moneda = "$";
              break;
            case 3:
              moneda = "€";
              break;
            default:
              moneda = "€"
              break;
          }

          let restaurante = {
            ...opciones.Sucursal,
            mesa: parseInt(findMesa.numero),
            suplemento: findMesa.suplemento,
            moneda: moneda
          };

          

          // if (localStorage.getItem('vuex')) {
          //   // LocalStorage disponible
          //   let localStorageSucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.sucursal;
    
          //    if(params.sucursal !== localStorageSucursal) {
          //     // Elimina todos los elementos
          //     localStorage.clear();
          //   }
          // }

          commit("agregarInfoRestaurante", restaurante);

          if(opciones) {
            //Verificar en localstorage que el restaurante no sea el mismo, si es otro, borrar toda la data del localstorage
            let localStorageSucursal = JSON.parse(localStorage.getItem('vuex')).restaurante.restaurante.sucursal;

            if(params.sucursal !== localStorageSucursal) {
              // Elimina todos los elementos
              localStorage.clear();
            }
          }

        } else {
          //Esta vacio, mostrar error
          localStorage.clear();
          commit("error", "QR Deshabilitado. Consulte proveedor para más info", { root: true });
        }
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    }
  },
  mutations: {
    agregarInfoRestaurante(state, payload) {
      state.restaurante = payload;
    }
  },
  getters: {
    logotipo: (state, _, rootState) => {
      if (state.restaurante.logo == null) {
        return "@/assets/images/logo.png";
      } else {
        return rootState.restaurante.restaurante.logo;
      }
    }
  }
};
