export const idiomas = {
  namespaced: true,
  state: {},
  actions: {
    async fetchOpciones({ commit, rootState }) {
      try {
        let response = await fetch("/db/opciones.json");
        let opciones = await response.json();

        let locale = {
          ...opciones,
          locale: rootState.restaurante.restaurante.locale_default
        }

        commit("agregarOpciones", locale);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    },
  },
  mutations: {
    agregarOpciones(state, payload) {
      state.options = payload;
    },
    cambiarIdioma(state, payload) {
      state.options.locale = payload
    }
  },
  getters: {
    OrdenarPorIdioma: (state, _, rootState) => {
      if (state.options) {
        const find = state.options.idiomas.filter(
          producto => producto.locale === rootState.idiomas.options.locale
        );
        
        return find;
      }
    },
  }
};
