import _ from "lodash";
import { API_URL } from "../../utils/constants";

export const carrito = {
  namespaced: true,
  state: {
    carrito: [],
    showModal: false,
    showButton: false,
    orden: null,
    setCheckoutStatus: null,
    loading: false,
    error: null
  },

  actions: {
    agregarCarrito({ commit, state }, producto) {
      if (state.carrito && !state.carrito.length) {
        commit("setAgregarProducto", producto);
        // commit('aumentar', producto.id)

        commit("mostrarBoton")
      } else {
        const cartItem = state.carrito.filter(
          (item) => item.id === producto.id
        );
        // console.log(cartItem)

        if (cartItem && cartItem.length > 0) {
          // console.log("existe")
          console.log(producto);

          if (producto.extras.length > 0 || producto.tamano.length > 0) {
            // console.log("Tiene un objeto, entonces guardemolo")
            const results = _.find(cartItem, producto);

            console.log(results);
            //Checkear el carrito que el objecto no este repetido
            if (results) {
              // console.log("existe sumalo dude")
              commit("aumentar", results);
            } else {
              // console.log("No existe, guardemolo")
              commit("setAgregarProducto", producto);

              commit("mostrarBoton")
            }
          } else {
            // console.log("esta vacio, sumar producto")
            // console.log(cartItem)
            commit("aumentar", cartItem[0]);
          }
        } else {
          // console.log("no existe")
          commit("setAgregarProducto", producto);

          commit("mostrarBoton")
        }
      }
    },

    async checkout({ commit, rootState, getters, state }, order) {

      //Cambiar status de orden
      commit('setCheckoutStatus', null)
      commit('loading', true)

      const carritoFiltrado = getters.cartProducts.map(({
        producto, cantidad, suplementos, extras, tamano
      }) => {
        return {
          Producto: producto,
          Cantidad: cantidad,
          Suplementos: suplementos,
          extras, 
          tamano
        }
      })
      
      var date = new Date();
      var dateStr =
        ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
        ("00" + date.getDate()).slice(-2) + "/" +
        date.getFullYear() + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
      
      const orderReady = 
          {
          "Pedido": {
              "Sucursal": rootState.restaurante.restaurante.eid,
              "Mesa": rootState.restaurante.restaurante.mesa,
              "FechaHora": dateStr,
              "Observaciones": order.observaciones,
              "Mac": "MOBILE",
              "Usuario": "AUTOPEDIDO",
              "UsuarioId": null,
              "Tipo": 1,
              "Token": null,
              "FormaPagoId": 2,
              // "Propina": 'No existe este campo todavia'
          },
          "Items": carritoFiltrado
      }
      
      //FormaPagoID: 1 , 2: efectivo , 3: Stripe

      try {
        let response = await fetch(
          `${API_URL}/realizarPedido`,
          {
            method: "POST",
            body: JSON.stringify(orderReady),
            headers: { 'Accept': 'application/json',
            'Content-Type': 'application/json' }
          }
        );

        let prod = await response.json();

        if(prod.success) {
          commit('agregarOrden', prod.pedido)

          let guardarProductos = {
            ...prod,
            productos: state.carrito
          }

          commit('ordenes/agregarOrden', guardarProductos, {root: true})

          commit('setCheckoutStatus', 'successful') 
          commit('setVaciar')
          commit('loading', false) 

          return prod.success;

        }


      } catch (err) {
        console.log(err);
        commit('setCheckoutStatus', 'failed')
          commit('loading', false)
      }
    }
  },
  mutations: {
    setProductos(state, payload) {
      state.productos = payload;
    },
    setAgregarProducto(state, payload) {
      let item = {
        _ref: new Date().getTime(),
        cantidad: 1,
        ...payload
      };
      state.carrito = [...state.carrito, item];
    },
    setVaciar(state) {
      state.carrito = [];
    },
    aumentar(state, id) {
      const cartItem = state.carrito.findIndex((item) => item._ref === id._ref);
      state.carrito[cartItem].cantidad = state.carrito[cartItem].cantidad + 1;
    },
    disminuir(state, payload) {
      if (payload.hasOwnProperty("_ref")) {
        const cartItem = state.carrito.findIndex(
          (item) => item._ref === payload._ref
        );
        if (cartItem >= 0) {
          state.carrito[cartItem].cantidad =
            state.carrito[cartItem].cantidad - 1;

          if (state.carrito[cartItem].cantidad === 0) {
            state.carrito.splice(cartItem, 1);
          }
        }
      } else {
        const cartItem = state.carrito.findIndex(
          (item) => item.id === payload.id
        );

        if (cartItem >= 0) {
          state.carrito[cartItem].cantidad =
            state.carrito[cartItem].cantidad - 1;

          if (state.carrito[cartItem].cantidad === 0) {
            state.carrito.splice(cartItem, 1);
          }
        }
      }
    },

    setCheckoutStatus (state, status) {
      state.checkoutStatus = status
    },

    mostrarPanel(state) {
      state.showModal = true;
    },

    ocultarModal(state) {
      state.showModal = false;
    },

    mostrarBoton(state) {
      state.showButton = true;
    },

    ocultarBoton(state) {
      state.showButton = false;
    },

    agregarOrden(state, payload) {
      state.orden = payload;
    },

    loading(state, payload) {
      state.loading = payload;
    }
  },
  getters: {
    cartProducts: (state) => {
      return state.carrito.map(
        ({ id, cantidad, _ref, suplementos, extras, tamano }) => {
          const product = state.carrito.find((product) => product.id === id);
          return {
            id: product.id,
            precio: product.total,
            producto: product.id,
            cantidad,
            _ref,
            extras,
            tamano,
            suplementos: suplementos.map(({ cantidad, id, nombre, precio }) => {
              return {
                cantidad,
                id,
                nombre,
                precio
              };
            })
          };
        }
      );
    },

    totalCantidad(state) {
      return state.carrito.reduce((acc, { cantidad }) => acc + cantidad, 0);
    },

    totalPrecio: (state, getters) => {
      return getters.cartProducts.reduce((total, product) => {
        return total + product.precio * product.cantidad;
      }, 0);
    }
  },

  mostrarPanel: (state) => state.showModal
};
