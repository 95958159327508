export const notificaciones = {
    namespaced: true,
    state: {},
    actions: {
      async fetchNotificaciones({ commit }) {
        try {
          let response = await fetch("/db/notificaciones.json");
          let opciones = await response.json();
  
          commit("agregarNotificaciones", opciones);
        } catch (err) {
          console.log(err); // TypeError: failed to fetch
        }
      },
    },
    mutations: {
      agregarNotificaciones(state, payload) {
        state.notificaciones = payload;
      }
    },
  };
  
  
