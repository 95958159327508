// import axios from "axios";

import { createLogger, createStore, mapGetters} from "vuex";
import VuexPersistence from 'vuex-persist'

import { restaurante } from "./modules/restaurante"; 
import { idiomas } from "./modules/idiomas";
import { categorias } from "./modules/categorias";
import { productos } from "./modules/productos";
import { carrito } from "./modules/carrito";
import { filtros } from "./modules/filtros"
import { menu } from "./modules/menu";
import { notificaciones } from "./modules/notificaciones";
import { resenas } from "./modules/resenas";
import { ordenes } from "./modules/ordenes";

const vuexLocal = new VuexPersistence({
  key: 'vuex',
  storage: window.localStorage,
  reducer: state => ({ ordenes: state.ordenes, productos: state.productos, carrito: state.carrito, restaurante: state.restaurante, idiomas: state.idiomas }),
});

export default createStore({
  plugins: [createLogger(), vuexLocal.plugin],
  state: {
    loading:false,
    ready: false,
    error: false,
    msg: null,
  },
  actions: {
    async cargandoInformacion({ dispatch, commit, state }, payload) {
      
      if(!state.ready) {
      commit("loading", true);     
      Promise.all([
        await dispatch("restaurante/fetchInfoRestaurante", { root: true, payload }),
        await dispatch("idiomas/fetchOpciones", { root: true }),
        await dispatch("categorias/fetchCategorias", { root: true }),
        await dispatch("notificaciones/fetchNotificaciones", { root: true }),
      ]).finally(() => {
        commit("loading", false);

        if(state.error){
          commit("ready", false);
        } else {
          commit("ready", true);
        }    
      });
    }
      
    },
  },
  mutations: {
    loading(state, payload) {
      state.loading = payload;
    },
    ready(state, payload) {
      state.ready = payload;
    },
    error(state, payload) {
      state.error = {
        error: true,
        ready: false,
        msg: payload
      }
    }
  },
  getters: {},
  modules: { restaurante, idiomas, categorias, productos, carrito, filtros, menu, notificaciones, resenas, ordenes },
});
