import { API_URL } from "../../utils/constants";

export const categorias = {
  namespaced: true,
  state: {
    categorias: [],
  },
  actions: {
    async fetchCategorias({ commit, rootState }) {
      try {
        //Obtener sucursal id
        let sucursalId = rootState.restaurante.restaurante.id;

        let response = await fetch(`${API_URL}/getCategoriasProductos?sucursal=${sucursalId}`);
        let categorias = await response.json();

        commit("addCategorias", categorias);
      } catch (err) {
        console.log(err); // TypeError: failed to fetch
      }
    },
  },
  mutations: {
    addCategorias(state, payload) {
      state.categorias = payload.categoriasproductos;
    },
  },
  getters: {
    OrdenarPorIdioma: (state, _, rootState) => {
      if (state.categorias) {
        const find = state.categorias.filter(
          producto => producto.locale === rootState.idiomas.options.locale
        );

        const activos = find.filter(
          activo => activo.activo === true
        )
        
        return activos;
      }
    },
  }
};
