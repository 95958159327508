<template>
  <!-- PRODUCT -->
  <div class="producto-carrito">
    <div class="carrito-imagen">
      <img
        class="img-fluid"
        v-if="carrito.logo"
        :src="`https://${restaurante.path}/uploads/images/productos/${restaurante.eid}/${restaurante.id}/${carrito.logo}`"
        alt=""
      />

      <img
        alt="No imagen"
        class="img-responsive ng-scope img-fluid"
        v-else
        src="@/assets/images/no-imagen-producto.jpg"
      />
    </div>
    <div class="carrito-descripcion">
      <div>
        <div class="carrito-descripcion-producto">
          {{ carrito.nombre }}
        </div>
        <div class="carrito-precio">{{ carrito.precio }}€</div>
        <div
          v-for="tamano of carrito.tamano"
          :key="tamano.tamano"
          class="carrito-extras"
        >
          <p class="mb-0 d-inline-block me-2 fw-bold">Tamaño:</p>
          <p class="mb-0 d-inline-block">
            {{ tamano.nombre }} | {{ tamano.precio }}{{ restaurante.moneda }}
          </p>
        </div>

        <p
          v-if="carrito.extras.length > 0"
          class="mb-0 d-inline-block me-2 fw-bold carrito-titular"
        >
          Extras:
        </p>
        <template
          v-for="extras of carrito.extras"
          :key="extras.nombre"
          class=""
        >
          <p class="carrito-extras">
            {{ extras.nombre }} - {{ extras.precio }}{{ restaurante.moneda }}
          </p>
        </template>
      </div>
      <div>
        <div class="carrito-cantidad">
          <div
            @click="disminuir(carrito)"
            class="carrito-iconos carrito-eliminar"
          >
            <i class="bi bi-dash-circle"></i>
          </div>
          <div class="carrito-cantidades">{{ carrito.cantidad }}</div>
          <div
            @click="aumentar(carrito)"
            class="carrito-iconos carrito-agregar"
          >
            <i class="bi bi-plus-circle"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END PRODUCT -->
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  props: ["carrito"],

  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);

    const aumentar = (producto) => {
      store.commit("carrito/aumentar", producto);
    };

    const disminuir = (producto) => {
      store.commit("carrito/disminuir", producto);
    };

    return { aumentar, disminuir, restaurante };
  },
};
</script>

<style lang="scss" scoped>
.carrito-extras {
  font-size: 12px;
  margin: 0;
  display: inline-block;
  margin-right: 10px;
  color: #9ca3af;
}

.carrito-titular {
  color: #9ca3af;
  font-size: 13px;
}
</style>