import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Sugerencias from "../views/Sugerencias.vue";
import Modal from "../views/Modal.vue"
import Confirmacion from "../views/Confirmacion.vue";
import QuienesSomos from "../views/QuienesSomos";
import Promociones from "../views/Promociones";
import Pedidos from "../views/Pedidos";
import ListaDeseos from "../views/ListaDeseos";
import Productos from "../views/Productos";
import Legal from "../views/Legal";
import Page404 from "../views/Page404";
import Default from "../views/Default";
import Contacto from "../views/Contacto";
import Reservas from "../views/Reservas";

const routes = [
  {
    path: '/:pathMatch(.*)*',
    component: Page404
  },
  {
    path: "/",
    component: Default
  },

  {
    path: "/:sucursal/:mesa",
    name: "Home",
    component: Home,
  },
  {
    path: "/:sucursal/:mesa/sugerencias",
    name: "Sugerencias",
    component: Sugerencias,
    children: [
      {
        path: 'producto/:product_id',
        name: 'destacado-modal',
        component: Modal
      },
    ]
  },
  {
    path: "/:sucursal/:mesa/productos/:id",
    name: "Productos",
    component: Productos,
    children: [
      {
        path: 'producto/:product_id',
        name: 'Producto-modal',
        component: Modal
      },
    ]
  },
  // {
  //   path: "/:sucursal/:mesa/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/:sucursal/:mesa/confirmacion",
    name: "confirmacion",
    component: Confirmacion,
  },
  {
    path: "/:sucursal/:mesa/reservar",
    name: "reservar",
    component: Reservas,
  },
  {
    path: "/:sucursal/:mesa/quienes-somos",
    name: "quienes-somos",
    component: QuienesSomos,
  },
  {
    path: "/:sucursal/:mesa/promociones",
    name: "Promociones",
    component: Promociones,
  },
  {
    path: "/:sucursal/:mesa/ordenes",
    name: "Ordenes",
    component: Pedidos,
  },
  {
    path: "/:sucursal/:mesa/contacto",
    name: "Contacto",
    component: Contacto,
  },
  {
    path: "/:sucursal/:mesa/lista-deseos",
    name: "lista-deseos",
    component: ListaDeseos,
    children: [
      {
        path: 'producto/:product_id',
        name: 'listaDeseo-modal',
        component: Modal
      },
    ]
  },
  {
    path: "/:sucursal/:mesa/legal",
    name: "legal",
    component: Legal,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
