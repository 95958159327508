<template>
  <div
    class="modal fade"
    id="resena"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-body">
          <div class="cerrar text-end mt-2 me-2" data-bs-dismiss="modal">
            <svg
              fill="#545454"
              width="25"
              viewBox="0 0 329.26933 329"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
              ></path>
            </svg>
          </div>

          <Loading v-if="loading" />

          <div v-if="preguntas" class="encuesta">
            <div v-if="!respuestas.valora">
              <h1 class="text-center fw-bold mb-4">¿Nos ayudas a mejorar?</h1>
              <div class="row">
                <form id="myForm" action="">
                  <div class="row">
                    <div class="col-xl-6 preguntas">
                      <div
                        v-for="(pregunta, index) in preguntas"
                        :key="pregunta.id"
                        class="contenedor-rating"
                      >
                        <div>
                          <p>{{ pregunta.nombre }}</p>
                        </div>
                        <div>
                          <div class="rate">
                            <!-- {{ respuestas.pregunta[index] }}
                          {{ index }} -->

                            <input
                              v-model="respuestas.preguntas[index].puntaje"
                              type="radio"
                              :id="`evaluacion1_${pregunta.id}`"
                              :name="`evaluacion_${pregunta.id}`"
                              :value="1"
                            />
                            <label
                              :for="`evaluacion1_${pregunta.id}`"
                              title="Pésima"
                            >
                              <img
                                src="@/assets/images/star-1.svg"
                                alt="Pésima"
                              /> </label
                            ><input
                              type="radio"
                              v-model="respuestas.preguntas[index].puntaje"
                              :id="`evaluacion2_${pregunta.id}`"
                              :name="`evaluacion_${pregunta.id}`"
                              :value="2"
                            />
                            <label
                              :for="`evaluacion2_${pregunta.id}`"
                              title="Mala"
                            >
                              <img
                                src="@/assets/images/star-2.svg"
                                alt="Mala"
                              /> </label
                            ><input
                              type="radio"
                              v-model="respuestas.preguntas[index].puntaje"
                              :id="`evaluacion3_${pregunta.id}`"
                              :name="`evaluacion_${pregunta.id}`"
                              :value="3"
                            />
                            <label
                              :for="`evaluacion3_${pregunta.id}`"
                              title="Regular"
                            >
                              <img
                                src="@/assets/images/star-3.svg"
                                alt="Regular"
                              /> </label
                            ><input
                              type="radio"
                              v-model="respuestas.preguntas[index].puntaje"
                              :id="`evaluacion4_${pregunta.id}`"
                              :name="`evaluacion_${pregunta.id}`"
                              :value="4"
                            />
                            <label
                              :for="`evaluacion4_${pregunta.id}`"
                              title="Buena"
                            >
                              <img
                                src="@/assets/images/star-4.svg"
                                alt="Buena"
                              />
                            </label>
                            <input
                              type="radio"
                              v-model="respuestas.preguntas[index].puntaje"
                              :id="`evaluacion5_${pregunta.id}`"
                              :name="`evaluacion_${pregunta.id}`"
                              :value="5"
                            />
                            <label
                              :for="`evaluacion5_${pregunta.id}`"
                              title="Excelente"
                            >
                              <img
                                src="@/assets/images/star-5.svg"
                                alt="Excelente"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="contenedor-rating">
                        <div class="box">
                          <p>Déjanos tu comentario</p>
                          <textarea
                            v-model="respuestas.comment"
                            class="form-control"
                            name="comment"
                            id=""
                            rows="10"
                          ></textarea>
                        </div>
                      </div>
                      <div class="contenedor-rating experience">
                        <div>
                          <p>
                            ¿Cómo fue tu experiencia en el restaurante, nos
                            ayudas con una referencia?
                          </p>
                        </div>
                        <div>
                          <div class="referencia">
                            <input
                              @click="enviarResena(1)"
                              type="radio"
                              id="referencia3"
                              name="referencia"
                              :value="1"
                            />
                            <label for="referencia3" title="Pésima">
                              <img src="@/assets/images/mal.svg" alt="Pésima" />
                            </label>
                            <input
                              @click="enviarResena(2)"
                              type="radio"
                              id="referencia2"
                              name="referencia"
                              :value="2"
                            />
                            <label for="referencia2" title="Mejorable">
                              <img
                                src="@/assets/images/regular.svg"
                                alt="Mejorable"
                              />
                            </label>
                            <input
                              @click="enviarResena(3)"
                              type="radio"
                              id="referencia1"
                              name="referencia"
                              :value="3"
                            />
                            <label
                              for="referencia1"
                              title="Excelente 
                            "
                            >
                              <img
                                src="@/assets/images/bien.svg "
                                alt="Excelente
                                    "
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div v-if="respuestas.valora >= 3">
              <div class="social-container">
                <h2 class="fw-bold text-center mb-5">
                  ¡Qué bien! ¿Puedes ayudarnos con una reseña en Google y
                  Tripadvisor?
                </h2>
                <div
                  onclick=' b=window.open( "https://www.tripadvisor.es/UserReviewEdit-"+localStorage.getItem("Tripadvisor")+"-.html", "", "scrollbars=yes, width=" + 800 + ", height=" + 600 + ", top=" + ((window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height) / 2 -600 / 2 + (void 0 !=window.screenTop ? window.screenTop : window.screenY)) + ", left=" + ((window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width) / 2 -800 / 2 + (void 0 !=window.screenLeft ? window.screenLeft : window.screenX)) );'
                  class="boton-social"
                >
                  <div class="social-icon">
                    <img src="@/assets/images/tripadvisor.svg" alt="" />
                  </div>
                  <div class="social-texto">
                    <div id="compartir">Compartir en</div>
                    <div id="redSocial" class="bold">Tripadvisor</div>
                  </div>
                </div>
                <div
                  onclick='b=window.open( "https://search.google.com/local/writereview?placeid="+localStorage.getItem("Google"), "", "scrollbars=yes, width=" + 800 + ", height=" + 600 + ", top=" + ((window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height) / 2 -600 / 2 + (void 0 !=window.screenTop ? window.screenTop : window.screenY)) + ", left=" + ((window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width) / 2 -800 / 2 + (void 0 !=window.screenLeft ? window.screenLeft : window.screenX)) );'
                  id="Google-button"
                  class="boton-social"
                >
                  <div class="social-icon">
                    <img src="@/assets/images/google.svg" alt="" />
                  </div>
                  <div class="social-texto">
                    <div>Compartir en</div>
                    <div class="bold">Google</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="respuestas.valora === 1 || respuestas.valora === 2">
              <div class="encuesta-fallida">
                <div class="emoji">
                  <img
                    v-if="respuestas.valora === 1"
                    src="@/assets/images/sad.svg"
                    alt=""
                  />
                  <img
                    v-if="respuestas.valora === 2"
                    src="@/assets/images/confused.svg"
                    alt=""
                  />
                </div>
                <div class="contenido">
                  <h2>Upsss... lo sentimos mucho!</h2>
                  <h4>
                    ¿ Te gustaria que nos pusieramos en contacto contigo ?
                  </h4>
                </div>
                <div class="contenido-botones">
                  <button @click="contactarUsuario(true)" class="boton verde">
                    SI, POR FAVOR
                  </button>
                  <button
                    @click="contactarUsuario(false)"
                    class="boton rojo"
                    data-bs-dismiss="modal"
                  >
                    NO GRACIAS
                  </button>
                </div>
              </div>
            </div>

            <div v-if="contactar" class="contenedor-formulario">
              <h2 class="fw-bold text-center">Dínos como contactar</h2>

              <div class="form-group">
                <label for="text" class="mb-2">Nombre</label>
                <input
                  type="email"
                  class="form-control mb-3"
                  id="text"
                  placeholder="Ingresa tu nombre"
                  name="text"
                  v-model="mensaje.nombre"
                />
              </div>
              <div class="form-group">
                <label for="email" class="mb-2">Email</label>
                <input
                  type="email"
                  class="form-control mb-3"
                  id="email"
                  placeholder="Ingresa tu correo electrónico"
                  name="email"
                  v-model="mensaje.correo"
                />
              </div>
              <div class="form-group">
                <label for="pwd" class="mb-2">Télefono</label>
                <input
                  type="number"
                  class="form-control mb-3"
                  id="telefono"
                  placeholder="Ingresa tu telefono"
                  name="telefono"
                  v-model="mensaje.telefono"
                />
              </div>

              <div class="checkbox mt-3">
                <div class="mb-3 form-check">
                  <input
                    input
                    type="checkbox"
                    name="remember"
                    class="form-check-input"
                    v-model="mensaje.confirmar"
                  />
                  <label class="form-check-label" for="exampleCheck1">
                    Acepto las
                    <div
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      class="d-inline-block terminos"
                    >
                      politica de privacidad de la RGPD
                    </div>
                  </label>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    <p>
                      En cumplimiento de lo dispuesto en el Reglamento Europeo
                      2016/679 de Protección de Datos de Carácter Personal,
                      RGPD, y la Ley Orgánica 3/2018, LOPD-GDD, CartaFood S.L,
                      le informa que, los datos de carácter personal que nos
                      proporcione a través de nuestro formulario de Contacto en
                      nuestra página web, se recogerán en nuestras bases de
                      datos siendo responsable CartaFood S.L.<br />
                      <br />
                      Podrá ejercer sus derechos de acceso, rectificación,
                      portabilidad y supresión de sus datos y los de limitación
                      y oposición a su tratamiento en los términos y en las
                      condiciones previstas en la normativa legal vigente,
                      dirigiéndose por escrito a CartaFood S.L. situada en C/
                      METGE JOSEP DARDER, 37 OFICINA 2 – Palma de Mallorca –
                      07008 Baleares (España), &#8203;&#8203;o si lo prefiere
                      con un e-mail a info@cartafood.es.<br />
                      <br />
                      De conformidad con el artículo 32 del RGPD y con el marco
                      normativo general de protección de datos, CartaFood S.L.
                      ha adoptado las medidas de seguridad, técnicas,
                      organizativas y de control proactivo necesarias. Todo
                      ello, en función del análisis previo del riesgo de las
                      operaciones de tratamiento realizadas.<br />
                      <br />
                      CartaFood S.L. se compromete a llevar un registro de las
                      actividades de tratamiento tal como se dispone en el
                      artículo 30.2 del RGPD.<br />
                      <br />
                      Le rogamos que comunique inmediatamente a CartaFood S.L.
                      cualquier modificación de sus datos de carácter personal
                      para que la información que contienen nuestros ficheros
                      esté siempre actualizada y no contenga errores.
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-grid gap-2">
                <button
                  type="button"
                  class="btn btn-lg btn-verde mt-3"
                  aria-label=""
                  :disabled="!mensaje.confirmar"
                  @click="enviarComentario()"
                  data-bs-dismiss="modal"
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import {
  computed,
  inject,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
} from "@vue/runtime-core";
import * as bootstrap from "bootstrap";

import Loading from "@/components/loading/Loading.vue";

export default {
  components: {
    Loading,
  },
  setup() {
    const store = useStore();
    const dialog = inject("$swal");

    const contactar = ref(false);

    const respuestas = reactive({
      preguntas: [
        {
          id: 63,
          puntaje: null,
        },
        {
          id: 67,
          puntaje: null,
        },
        {
          id: 65,
          puntaje: null,
        },
        {
          id: 64,
          puntaje: null,
        },
        {
          id: 69,
          puntaje: null,
        },
      ],
      comment: "",
      puesto: null,
      valora: null,
    });

    const mensaje = reactive({
      nombre: null,
      telefono: null,
      correo: null,
      confirmar: false,
    });

    onMounted(() => {
      let myModal = new bootstrap.Modal(document.getElementById("resena"), {
        backdrop: "true",
      });
      myModal.show();

      document
        .getElementById("resena")
        .addEventListener("hidden.bs.modal", function (event) {
          backdrop();
        });
    });

    onBeforeMount(() => {
      store.dispatch("resenas/fetchEncuesta");
    });

    const enviarResena = (valor) => {
      if (
        respuestas.preguntas[0].puntaje === null ||
        respuestas.preguntas[1].puntaje === null ||
        respuestas.preguntas[2].puntaje === null ||
        respuestas.preguntas[3].puntaje === null ||
        respuestas.preguntas[4].puntaje === null
      ) {
        dialog({
          position: "bottom-right",
          toast: true,
          showConfirmButton: false,
          icon: "error",
          title: "Debes rellenar todas las preguntas",
          timer: 2000,
        });
      } else {
        //Enviar data por medio de vuex
        respuestas.valora = valor;
        setTimeout(() => {
          store.dispatch("resenas/enviarEncuesta", respuestas);
        }, 300);
      }
    };

    const contactarUsuario = (e) => {
      if (e) {
        contactar.value = true;
        respuestas.valora = -1;
      } else {
        backdrop();
      }
    };

    const enviarComentario = () => {
      if (mensaje.telefono === null || mensaje.nombre === null) {
        dialog({
          position: "bottom-right",
          toast: true,
          showConfirmButton: false,
          icon: "error",
          title: "Debes de colocar tu teléfono y nombre",
          timer: 2000,
        });
      } else {
        setTimeout(() => {
          store.dispatch("resenas/enviarComentario", mensaje);

          dialog({
            position: "center",
            toast: false,
            showConfirmButton: false,
            icon: "success",
            title: "Gracias, pronto nos contactaremos",
            timer: 2000,
          });

          backdrop();
        }, 100);
      }
    };

    const preguntas = computed(() => store.getters["resenas/OrdenarPorIdioma"]);
    const loading = computed(() => store.state.resenas.loading);

    const backdrop = () => {
      store.commit("menu/mostrarResena", false);
    };

    return {
      preguntas,
      enviarResena,
      respuestas,
      contactarUsuario,
      loading,
      contactar,
      mensaje,
      enviarComentario,
    };
  },
};
</script>

<style lang="scss" scoped>
.cerrar {
  cursor: pointer;
}
//Contenedor de la encuesta
.encuesta {
  color: #4a4f4b;
  background-color: #fff;
  h2 {
    margin-bottom: 25px;
    font-weight: bold;
  }

  p {
    font-size: 16px;
    color: #303030;
  }

  input[type="submit"] {
    background: #46b975;
    box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    color: #fff;
    font-size: 18px;
    padding: 10px 25px;
    border: 0;
    margin-top: 15px;
    float: right;
    clear: both;
  }

  input[type="submit"]:hover {
    background: #196639;
  }

  @media (max-width: 992px) {
    padding: 25px 0;

    input[type="submit"] {
      display: block;
      margin: 0 auto;
      float: none;
    }
  }

  @media (max-width: 992px) {
    h2 {
      font-size: 18px;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  .swal2-popup.swal2-modal.swal2-show {
    width: 100% !important;
  }
}

//Contenedor de cada pregunta
.contenedor-rating {
  display: flex;
  padding: 25px;
  background: #f9fafb;
  // background: #f9f9f9;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 25px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .box {
    width: 100%;
    padding: 8px;

    p {
      margin-bottom: 15px;
    }

    @media (max-width: 992px) {
      padding: 0 0 15px 0;

      textarea {
        height: 130px;
      }
    }
  }

  p {
    margin: 0;
    font-weight: bold;
  }

  textarea {
    min-width: 100%;
  }

  @media (max-width: 1250px) {
    flex-flow: column;
  }
}

//Estilos para la pregunta final
.experience {
  flex-flow: column;
  text-align: center;
  background: #fff;

  p {
    margin-bottom: 15px;
    color: #4a4f4b;
  }
}

.referencia {
  label {
    cursor: pointer;
    transition: all 0.5s ease;
  }

  label:hover {
    opacity: 0.8;
  }

  input[type="radio"] {
    display: none;
  }
}

.rate {
  display: flex;

  img {
    width: 55px;
  }

  label {
    margin: 0;
    opacity: 0.45;
    cursor: pointer;
    transition: all 0.5s ease;
  }

  label:hover {
    opacity: 1;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    opacity: 1;
  }
}

//Botones social
.boton-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 25px;
  color: #fff;
  width: 235px;
  font-family: Sans-Serif;
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 25px;
  margin-right: 25px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#46b975),
    to(#18954b)
  );
  background: linear-gradient(180deg, #46b975 0%, #18954b 100%);
  -webkit-box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media (max-width: 993px) {
  .boton-social {
    margin-right: 0px;
  }
}
.boton-social:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#46b975),
    to(#0f6c35)
  );
  background: linear-gradient(180deg, #46b975 0%, #0f6c35 100%);
}
.boton-social .social-icon {
  margin-right: 15px;
}
.boton-social .social-icon img {
  width: 50px;
}
.boton-social:last-child {
  margin-right: 0;
}
.boton-social .social-texto {
  text-align: left;
  padding-left: 15px;
  border-left-width: 1px;
  border-left-color: rgba(255, 255, 255, 0.29);
  border-left-style: solid;
}
.boton-social .social-texto .bold {
  font-weight: bold;
  font-size: 20px;
}
.swal2-header {
  margin-bottom: 25px;
}
.social-container {
  display: flex;
  margin: 25px auto;
  flex-flow: wrap;
  justify-content: center;
  flex-wrap: wrap;
}

.encuesta-fallida {
  position: relative;
  text-align: center;
  padding-bottom: 35px;
}

.encuesta-fallida .emoji {
  margin: 0 auto 50px;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  background: #fff;
  font-size: 90px;
  position: absolute;
  top: -111px;
  right: 0;
  left: 0;
  -webkit-box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.2);
}

.encuesta-fallida .emoji img {
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.encuesta-fallida h2 {
  font-weight: bold;
  margin-bottom: 25px;
}

@media (max-width: 600px) {
  .encuesta-fallida h2 {
    font-size: 22px;
  }
}

@media (max-width: 600px) {
  .encuesta-fallida h4 {
    font-size: 16px;
  }
}

.encuesta-fallida .contenido {
  padding-top: 75px;
  margin-bottom: 16px;
}

.boton {
  -webkit-box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 18px;
  padding: 10px 25px;
  border: 0;
  border-radius: 6px;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.boton:last-child {
  margin-right: 0;
}

@media (max-width: 600px) {
  .boton {
    margin-right: 0;
    width: 200px;
  }
}

.verde {
  background: #46b975;
}

.verde:hover {
  background: #148b46;
}

.rojo {
  background: #dc2626;
}

.rojo:hover {
  background: #8b0c0c;
}

.naranja {
  background: #f59e0b;
}

.naranja:hover {
  background: #b97602;
}

.terminos {
  font-weight: bold;
  color: #196639;
  text-decoration: underline;
  cursor: pointer;
}

.contenedor-formulario {
  width: 45%;
  margin: 0 auto;

  @media (max-width: 992px) {
    width: 100%;
  }
}

//Estilos para las estrellas
// .rate {
//   float: left;
//   height: 46px;
//   padding: 0 10px;
// }
// .rate:not(:checked) > input {
//   position: absolute;
//   top: -9999px;
// }
// .rate:not(:checked) > label {
//   float: right;
//   width: 1em;
//   overflow: hidden;
//   white-space: nowrap;
//   cursor: pointer;
//   font-size: 30px;
//   color: #e5e7eb;
// }
// // .rate:not(:checked) > label:before {
// //   content: "★ ";
// // }
// .rate > input:checked ~ label {
//   color: #fbbf24;
// }
// .rate:not(:checked) > label:hover,
// .rate:not(:checked) > label:hover ~ label {
//   color: #fbbf24;
// }
// .rate > input:checked + label:hover,
// .rate > input:checked + label:hover ~ label,
// .rate > input:checked ~ label:hover,
// .rate > input:checked ~ label:hover ~ label,
// .rate > label:hover ~ input:checked ~ label {
//   color: #fbbf24;
// }
</style>