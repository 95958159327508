<template>
  <div class="collapse" id="alergenos-menu">
    <div class="alergenos-contenedor">
      <div class="row">
        <div class="col-12">
          <h5>Alérgenos</h5>
          <ul>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/altramuces.png" alt="" />
                  <span>Altramuces</span>
                </div>
              </label>
            </li>

            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/apio.png" alt="" />
                  <span>Apio</span>
                </div>
              </label>
            </li>

            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/cacahuate.png" alt="" />
                  <span>Cacahuate</span>
                </div>
              </label>
            </li>

            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/crustaceo.png" alt="" />
                  <span>Crustaceo</span>
                </div>
              </label>
            </li>

            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/dioxido.png" alt="" />
                  <span>Dioxido</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/frutos.png" alt="" />
                  <span>Frutos</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/gluten.png" alt="" />
                  <span>Gluten</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/huevo.png" alt="" />
                  <span>Huevo</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/lacteo.png" alt="" />
                  <span>Lacteo</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/moluscos.png" alt="" />
                  <span>Moluscos</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/mostaza.png" alt="" />
                  <span>Mostaza</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/pescado.png" alt="" />
                  <span>Pescado</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img
                    src="@/assets/images/alergenos/proteina-leche.png"
                    alt=""
                  />
                  <span>Proteina-leche</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/sesamo.png" alt="" />
                  <span>Sesamo</span>
                </div>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" />
                <div class="alergeno">
                  <img src="@/assets/images/alergenos/soja.png" alt="" />
                  <span>Soja</span>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>