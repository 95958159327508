<template>
  <router-view></router-view>
  <Layout>
    <div v-if="productos.length > 0" class="container-xl mobile-m">
      <div class="contenedor-productos">
        <ListaDeseo
          v-for="producto in productos"
          :key="producto.id"
          :props="producto"
        />
      </div>
    </div>

    <div class="no-disponible text-center" v-else>
      <h3 class="mt-5">No se encuentran productos disponibles 😱😱</h3>
      <router-link to="/" class="mt-3">Volver al menu</router-link>
    </div>
  </Layout>
</template>

<script>
import { watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onBeforeMount, computed } from "@vue/runtime-core";

import ListaDeseo from "@/components/producto/ListaDeseo.vue";
import Layout from "@/layout/Layout.vue";
// import Loading from "@/components/loading/Loading.vue";

export default {
  components: {
    ListaDeseo,
    Layout,
  },

  setup() {
    const route = useRoute();
    const store = useStore();

    const productos = computed(() => store.state.productos.listaDeseo);

    const loading = computed(() => store.state.productos.loading);

    watch(
      () => route.params.id,
      (value, prevValue) => {
        console.log(value, prevValue);
        store.dispatch("productos/fetchDestacados", value);
      }
    );

    return { productos, loading };
  },
};
</script>

<style>
</style>