<template>
  <Layout>
    <div class="container mobile-m mt-5 mb-5">
      <div class="row">
        <div class="col-md-6">
          <h1 class="">Contacto</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
            ratione reiciendis nostrum deleniti expedita eos, necessitatibus
            doloribus beatae accusantium voluptatibus ad perferendis consequatur
            repellendus soluta reprehenderit repellat non obcaecati ipsa!
          </p>
        </div>
        <form class="col-md-6 bg-form mb-5 shadow">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="inputEmail4" class="form-label">Nombre</label>
              <input type="text" class="form-control" id="inputEmail4" />
            </div>
            <div class="col-md-12 mb-3">
              <label for="inputPassword4" class="form-label">Telefono</label>
              <input type="number" class="form-control" id="inputPassword4" />
            </div>
            <div class="col-md-12 mb-3">
              <label for="inputPassword4" class="form-label">Email</label>
              <input type="email" class="form-control" id="inputPassword4" />
            </div>
            <div class="col-12 mt-2 mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >Mensaje</label
              >
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <div class="col-12">
              <button type="submit" class="btn btn-verde w-100">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue";
export default {
  components: {
    Layout,
  },
};
</script>

<style lang="scss" scoped>
.bg-form {
  padding: 25px;
  background: #f3f6f9;
  border-radius: 8px;
  border: 1px solid #ebeff3;
}
</style>