<template>
  <nav class="menu-bar">
    <div class="container-flex">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <!-- <img
            alt="Vue logo"
            :src="require(`@/assets/images/${restaurante.logo}`)"
          /> -->
          <img
            v-if="restaurante.logo == null"
            :src="require(`@/assets/images/logo.png`)"
            alt=""
          />
          <img
            v-else
            :src="`https://${restaurante.path}/uploads/images/sucursales/${restaurante.logo}`"
            alt=""
          />
        </router-link>
      </div>

      <div class="elementos-menu">
        <ul>
          <router-link
            v-slot="{ isActive }"
            class="menu"
            :to="{ name: 'Home' }"
          >
            <li :class="[isActive && 'menu active']">
              <span>
                <svg
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 23 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#a)" fill="#283646">
                    <path
                      d="m22.08 19.787l-8.7181-8.7182-10.186-10.186h-0.79601l-0.16254 0.41301c-0.42899 1.0901-0.61228 2.2051-0.52999 3.2242 0.09379 1.1617 0.53512 2.1674 1.2763 2.9086l7.9515 7.9514 1.0487-1.0487 7.3512 8.2199c0.7312 0.7313 1.9899 0.7747 2.7646 0 0.7622-0.7623 0.7622-2.0025 0-2.7647z"
                    />
                    <path
                      d="m7.2303 13.539l-6.2276 6.2275c-0.76218 0.7622-0.76218 2.0024 0 2.7646 0.72406 0.7241 1.9766 0.788 2.7646 0l6.2276-6.2276-2.7647-2.7645z"
                    />
                    <path
                      d="m21.713 4.5851l-3.5326 3.5326-0.9216-0.92155 3.5326-3.5326-0.9216-0.92156-3.5325 3.5326-0.9216-0.92155 3.5326-3.5326-0.9215-0.92151-4.6078 4.6078c-0.5636 0.56369-0.9002 1.3122-0.9478 2.1076-0.0121 0.20154-0.0577 0.39977-0.1335 0.58832l2.9932 2.9932c0.1885-0.0758 0.3868-0.1215 0.5883-0.1335 0.7955-0.0475 1.5439-0.3841 2.1076-0.9478l4.6078-4.6078-0.9216-0.9216z"
                    />
                  </g>
                  <defs>
                    <clipPath id="a">
                      <rect
                        transform="translate(.42432 .8833)"
                        width="22.233"
                        height="22.233"
                        fill="#fff"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </span>

              <span> Menu </span>
            </li>
          </router-link>

          <router-link
            v-slot="{ isActive }"
            class="lista-deseos"
            :to="{ name: 'lista-deseos' }"
          >
            <li :class="[isActive && 'lista-deseos active']">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.3988 2.84889C20.3017 1.59038 18.8133 0.883301 17.2613 0.883301C15.7093 0.883301 14.2209 1.59038 13.1237 2.84889L11.9963 4.14154L10.8688 2.84889C8.5837 0.228975 4.87882 0.228975 2.59371 2.84889C0.308607 5.4688 0.308607 9.71652 2.59371 12.3364L3.72117 13.6291L11.9963 23.1166L20.2714 13.6291L21.3988 12.3364C22.4965 11.0785 23.1132 9.37205 23.1132 7.59266C23.1132 5.81327 22.4965 4.10681 21.3988 2.84889Z"
                    fill="#283646"
                  />
                </svg>
              </span>

              <span> Lista de deseos </span>
            </li>
          </router-link>

          <router-link
            v-slot="{ isActive }"
            class="destacados"
            :to="{ name: 'Sugerencias' }"
            exact
          >
            <li :class="[isActive && 'destacados active']">
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.1634 1.48834C11.3381 1.11788 11.6987 0.883301 12.0935 0.883301C12.4883 0.88325 12.8489 1.11783 13.0236 1.48829L15.6558 7.06904C15.8069 7.38934 16.0989 7.6114 16.4367 7.66278L22.3223 8.55778C22.713 8.61718 23.0376 8.9036 23.1596 9.29647C23.2816 9.6894 23.1799 10.1207 22.8971 10.4091L18.6382 14.7532C18.3937 15.0026 18.2822 15.3618 18.3399 15.7139L19.3451 21.8478C19.4118 22.255 19.2518 22.6665 18.9325 22.9093C18.6131 23.1522 18.1896 23.1842 17.8402 22.992L12.5762 20.0961C12.274 19.9299 11.9131 19.9299 11.6109 20.0961L6.34681 22.9919C5.99735 23.1842 5.57392 23.1521 5.25451 22.9093C4.9351 22.6664 4.77516 22.2549 4.8419 21.8477L5.84734 15.7139C5.90502 15.3618 5.79349 15.0026 5.54906 14.7532L1.29029 10.4091C1.00762 10.1207 0.90584 9.6894 1.02784 9.29642C1.14983 8.90349 1.47443 8.61713 1.86509 8.55773L7.75071 7.66267C8.08854 7.61129 8.38061 7.38924 8.53169 7.06889L11.1634 1.48834Z"
                    fill="#283646"
                  />
                </svg>
              </span>

              <span> Sugerencias </span>
            </li>
          </router-link>

          <li class="llamar" @click="OnLlamarCamarero()">
            <span>
              <svg
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M16.3878 10.5566C17.2345 9.53363 17.7441 8.2216 17.7441 6.79268C17.7441 3.20794 14.7478 0.883301 11.8383 0.883301C8.73206 0.883301 5.93262 3.34916 5.93262 6.79268C5.93262 8.27002 6.44216 9.53363 7.28894 10.5566C3.58509 11.062 0.72168 14.2474 0.72168 18.09V18.762L0.93802 18.9562C3.7742 21.5027 7.39185 22.966 11.187 23.1166V17.221H12.4897V23.1166C16.2848 22.966 19.9025 21.5027 22.7387 18.9563L22.955 18.762V18.0901C22.955 14.2474 20.0916 11.062 16.3878 10.5566ZM5.10755 20.2356C4.00696 19.6835 2.97072 18.9941 2.02441 18.1766V18.09C2.02441 15.7898 3.26297 13.7739 5.10755 12.6739V20.2356ZM14.6609 16.8624L11.8383 15.7891L9.01576 16.8624V13.2345L11.8383 14.3077L14.6609 13.2345V16.8624ZM11.8383 11.3985C9.47367 11.3985 7.52009 9.60498 7.2641 7.30609C8.11713 7.7643 9.07646 8.00934 10.0579 8.00934C11.4966 8.00934 12.8828 7.48565 13.961 6.53469C14.4129 6.13614 14.7972 5.67463 15.1049 5.16856C15.4142 5.87907 15.8628 6.52818 16.4325 7.07472C16.2863 9.48352 14.2818 11.3985 11.8383 11.3985ZM21.6523 18.1766C20.706 18.9941 19.6697 19.6835 18.5691 20.2356V12.674C20.4137 13.7739 21.6523 15.7898 21.6523 18.0901V18.1766Z"
                    fill="#283646"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="22.2333"
                      height="22.2333"
                      fill="white"
                      transform="translate(0.72168 0.883301)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <span> Camarero </span>
          </li>

          <li class="resena" @click="OnResena()">
            <span>
              <svg
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M0.0532227 3.84785V16.0762C0.0532227 17.2812 1.07151 18.2995 2.27656 18.2995H5.241V22.0051C5.241 22.1581 5.33512 22.2956 5.47779 22.3508C5.52151 22.3678 5.56672 22.3756 5.61156 22.3756C5.71383 22.3756 5.81388 22.3334 5.88577 22.2545L9.4809 18.2995H20.0632C21.2683 18.2995 22.2866 17.2812 22.2866 16.0762V3.84785C22.2866 2.6428 21.2683 1.62451 20.0632 1.62451H2.27656C1.07151 1.62451 0.0532227 2.6428 0.0532227 3.84785ZM14.8754 9.77673C14.8754 8.95929 15.5402 8.29451 16.3577 8.29451C17.1751 8.29451 17.8399 8.95929 17.8399 9.77673C17.8399 10.5942 17.1751 11.259 16.3577 11.259C15.5402 11.259 14.8754 10.5942 14.8754 9.77673ZM9.68767 9.77673C9.68767 8.95929 10.3524 8.29451 11.1699 8.29451C11.9873 8.29451 12.6521 8.95929 12.6521 9.77673C12.6521 10.5942 11.9873 11.259 11.1699 11.259C10.3524 11.259 9.68767 10.5942 9.68767 9.77673ZM4.49989 9.77673C4.49989 8.95929 5.16467 8.29451 5.98211 8.29451C6.79956 8.29451 7.46433 8.95929 7.46433 9.77673C7.46433 10.5942 6.79956 11.259 5.98211 11.259C5.16467 11.259 4.49989 10.5942 4.49989 9.77673Z"
                    fill="#283646"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect
                      width="22.2333"
                      height="22.2333"
                      fill="white"
                      transform="translate(0.0532227 0.883301)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>

            <span> Reseña </span>
          </li>

          <router-link class="promociones" :to="{ name: 'Promociones' }">
            <li>
              <span>
                <svg
                  viewBox="0 0 511.548 511.548"
                  style="enable-background: new 0 0 511.548 511.548"
                  xml:space="preserve"
                  width="24"
                  height="24"
                >
                  <g>
                    <path
                      d="M306.248,338.724c-4.329,0-8.658,3.936-8.658,17.12c0,13.184,4.329,17.12,8.658,17.12s8.658-3.936,8.658-17.12   C314.906,342.659,310.577,338.724,306.248,338.724z"
                    />
                    <path
                      d="M205.301,273.787c-4.329,0-8.658,3.936-8.658,17.12c0,13.184,4.329,17.12,8.658,17.12c4.329,0,8.658-3.936,8.658-17.12   C213.959,277.723,209.63,273.787,205.301,273.787z"
                    />
                    <path
                      d="M405.561,181.48c-43.372-47.903-69.147-90.072-83.134-117.013c-15.148-29.181-20.112-47.276-20.15-47.42L297.768,0   l-16.104,7.183c-1.882,0.839-46.376,21.13-71.307,70.991c-12.515,25.031-15.018,52.9-14.913,71.87   c0.061,11.04-7.761,20.626-18.598,22.793c-7.598,1.518-15.414-0.844-20.898-6.328l-29.997-29.997l-10.319,14.229   c-1.071,1.477-26.289,36.256-30.88,43.205c-22.419,33.937-34.109,73.47-33.806,114.325c0.406,54.565,21.864,105.686,60.421,143.948   c38.555,38.259,89.84,59.329,144.408,59.329c112.945-0.001,204.832-91.888,204.832-204.833   C460.608,265.764,440.544,220.118,405.561,181.48z M306.248,394.215c-20.858,0-36.601-13.774-36.601-38.371   c0-24.4,15.742-38.372,36.601-38.372s36.601,13.971,36.601,38.372C342.849,380.441,327.106,394.215,306.248,394.215z    M286.177,254.503h33.255l-94.06,137.744h-33.255L286.177,254.503z M168.701,290.907c0-24.4,15.742-38.371,36.601-38.371   s36.601,13.971,36.601,38.371c0,24.597-15.742,38.372-36.601,38.372S168.701,315.504,168.701,290.907z"
                    />
                  </g>
                </svg>
              </span>

              <span> Promociones </span>
            </li>
          </router-link>

          <li>
            <div class="idioma idiomaBandera" @click="OnMostrarIdiomas()">
              <img
                :src="
                  require(`@/assets/images/idiomas/${banderaIdioma[0].imagen}`)
                "
                alt="Idiomas"
              />
            </div>
          </li>
        </ul>
      </div>

      <div class="opcion">
        <ul>
          <li>
            <button class="idioma" @click="OnMostrarIdiomas()">
              <img
                :src="
                  require(`@/assets/images/idiomas/${banderaIdioma[0].imagen}`)
                "
                alt="Idiomas idiomaBandera"
              />
            </button>
          </li>

          <li class="promociones-alerta">
            <router-link :to="{ name: 'Promociones' }">
              <span>
                <svg
                  viewBox="0 0 511.548 511.548"
                  style="enable-background: new 0 0 511.548 511.548"
                  xml:space="preserve"
                  width="24"
                  height="24"
                >
                  <g>
                    <path
                      d="M306.248,338.724c-4.329,0-8.658,3.936-8.658,17.12c0,13.184,4.329,17.12,8.658,17.12s8.658-3.936,8.658-17.12   C314.906,342.659,310.577,338.724,306.248,338.724z"
                    />
                    <path
                      d="M205.301,273.787c-4.329,0-8.658,3.936-8.658,17.12c0,13.184,4.329,17.12,8.658,17.12c4.329,0,8.658-3.936,8.658-17.12   C213.959,277.723,209.63,273.787,205.301,273.787z"
                    />
                    <path
                      d="M405.561,181.48c-43.372-47.903-69.147-90.072-83.134-117.013c-15.148-29.181-20.112-47.276-20.15-47.42L297.768,0   l-16.104,7.183c-1.882,0.839-46.376,21.13-71.307,70.991c-12.515,25.031-15.018,52.9-14.913,71.87   c0.061,11.04-7.761,20.626-18.598,22.793c-7.598,1.518-15.414-0.844-20.898-6.328l-29.997-29.997l-10.319,14.229   c-1.071,1.477-26.289,36.256-30.88,43.205c-22.419,33.937-34.109,73.47-33.806,114.325c0.406,54.565,21.864,105.686,60.421,143.948   c38.555,38.259,89.84,59.329,144.408,59.329c112.945-0.001,204.832-91.888,204.832-204.833   C460.608,265.764,440.544,220.118,405.561,181.48z M306.248,394.215c-20.858,0-36.601-13.774-36.601-38.371   c0-24.4,15.742-38.372,36.601-38.372s36.601,13.971,36.601,38.372C342.849,380.441,327.106,394.215,306.248,394.215z    M286.177,254.503h33.255l-94.06,137.744h-33.255L286.177,254.503z M168.701,290.907c0-24.4,15.742-38.371,36.601-38.371   s36.601,13.971,36.601,38.371c0,24.597-15.742,38.372-36.601,38.372S168.701,315.504,168.701,290.907z"
                    />
                  </g>
                </svg>
              </span>
            </router-link>
          </li>

          <li>
            <div class="notificaciones" @click="OnMostrarNotificaciones()">
              <i class="bi bi-bell-fill"></i>
              <div class="notificacion-circulo">
                {{ notificaciones.length }}
              </div>
            </div>
          </li>
          <li class="cesta position-relative" @click="mostrarCarrito()">
            <!-- <router-link to="/pedidos" class="cuenta"> -->
            <div v-if="carrito.length > 0" class="notificacion-circulo">
              {{ carrito.length }}
            </div>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 490 490"
              style="enable-background: new 0 0 490 490"
              xml:space="preserve"
            >
              <g>
                <polygon
                  points="409.1,116.7 367.1,116.7 367.1,87.5 409.1,87.5 409.1,0 262.5,0 262.5,87.5 304.9,87.5 304.9,116.7 79.3,116.7 
				79.3,206.5 409.1,206.5 			"
                />
                <path
                  d="M414.9,225.9H75.1L4.3,384.6h481.4L414.9,225.9z M110.4,367.1H76.6c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7h33.8
				c5.4,0,9.7,4.3,9.7,9.7C120.2,362.8,115.5,367.1,110.4,367.1z M127.6,332.5H98c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h29.6
				c5.4,0,9.7,4.3,9.7,9.7S133,332.5,127.6,332.5z M140.8,297.9h-26.1c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7S146.2,297.9,140.8,297.9z M151.7,262.9h-23.3c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7S157.1,262.9,151.7,262.9z M211.6,367.1h-33.8c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7h33.8
				c5.4,0,9.7,4.3,9.7,9.7C221.3,362.8,216.6,367.1,211.6,367.1z M215.8,332.5h-29.6c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h29.6
				c5.4,0,9.7,4.3,9.7,9.7S220.9,332.5,215.8,332.5z M218.9,297.9h-26.1c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7S224.4,297.9,218.9,297.9z M221.7,262.9h-23.3c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7S227.1,262.9,221.7,262.9z M268.3,243.4h23.3c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-23.3
				c-5.4,0-9.7-4.3-9.7-9.7S262.9,243.4,268.3,243.4z M261.3,288.2c0-5.4,4.3-9.7,9.7-9.7h26.1c5.4,0,9.7,4.3,9.7,9.7
				s-4.3,9.7-9.7,9.7H271C265.6,297.9,261.3,293.2,261.3,288.2z M264.4,322.8c0-5.4,4.3-9.7,9.7-9.7h29.6c5.4,0,9.7,4.3,9.7,9.7
				s-4.3,9.7-9.7,9.7h-29.6C269.1,332.5,264.4,328.2,264.4,322.8z M312.7,367.1h-33.8c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7
				h33.8c5.4,0,9.7,4.3,9.7,9.7C322.4,362.8,317.7,367.1,312.7,367.1z M328.6,253.2c0-5.4,4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-23.3C332.9,262.9,328.6,258.6,328.6,253.2z M339.5,288.2c0-5.4,4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-26.1C343.8,297.9,339.5,293.2,339.5,288.2z M352.7,322.8c0-5.4,4.3-9.7,9.7-9.7H392
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-29.6C357,332.5,352.7,328.2,352.7,322.8z M413.8,367.1H380c-5.4,0-9.7-4.3-9.7-9.7
				c0-5.4,4.3-9.7,9.7-9.7h33.8c5.4,0,9.7,4.3,9.7,9.7C423.5,362.8,418.8,367.1,413.8,367.1z"
                />
                <path
                  d="M0,404.1V490h490v-85.9H0z M245,465.5c-12.8,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3c12.8,0,23.3,10.5,23.3,23.3
				S257.8,465.5,245,465.5z"
                />
              </g>
            </svg>
            <!-- </router-link> -->
          </li>

          <li>
            <button id="opcion" @click="OnMostrarSide()">
              <img src="@/assets/images/opciones.svg" alt="" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <Idiomas v-if="mostrarIdiomas" />
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

import Idiomas from "../idiomas/Idiomas.vue";

export default {
  components: {
    Idiomas,
  },

  setup() {
    const store = useStore();

    const mostrarIdiomas = computed(() => store.state.menu.mostrarIdiomas);
    const restaurante = computed(() => store.state.restaurante.restaurante);
    const notificaciones = computed(
      () => store.state.notificaciones.notificaciones
    );

    const carrito = computed(() => store.state.carrito.carrito);

    const banderaIdioma = computed(
      () => store.getters["idiomas/OrdenarPorIdioma"]
    );

    const OnMostrarIdiomas = () => {
      store.commit("menu/mostrarIdiomas", true);
    };

    const OnMostrarNotificaciones = () => {
      store.commit("menu/mostrarNotificaciones", true);
    };

    const OnLlamarCamarero = () => {
      store.commit("menu/mostrarCamarero", true);
    };

    const OnMostrarSide = () => {
      store.commit("menu/mostrarSidebar", true);
    };

    const OnResena = () => {
      store.commit("menu/mostrarResena", true);
    };

    const mostrarCarrito = () => {
      store.commit("carrito/mostrarPanel");
    };

    return {
      mostrarIdiomas,
      restaurante,
      notificaciones,
      banderaIdioma,
      carrito,
      OnMostrarIdiomas,
      OnLlamarCamarero,
      OnMostrarNotificaciones,
      OnMostrarSide,
      OnResena,
      mostrarCarrito,
    };
  },
};
</script>

<style lang="scss">
.idiomaBandera {
  width: 40px;
  img {
    width: 100%;
  }
}
.menu-bar {
  background: #f3f6f9;
  box-shadow: 0px 14.8222px 37.0556px rgba(0, 0, 0, 0.1);
  height: 80px;
  display: flex;
  width: 100%;

  @media (max-width: 640px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .container-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    @media (max-width: 960px) {
      padding: 0 15px;
    }
  }

  .logo {
    img {
      width: 105px;
    }
  }

  .elementos-menu {
    ul {
      display: flex;
      padding: 0;
      margin: 0;

      @media (max-width: 1300px) {
        display: none;
      }
    }

    li {
      list-style-type: none;
      margin: 0 5px;
      padding: 0 10px 2px;
      display: flex;
      align-items: center;
    }

    .active {
      /* border-bottom-color: #00a729;
      border-bottom-style: solid;
      border-bottom-width: 2px; */

      &.destacados {
        border-bottom-color: #ffaa00;

        a {
          color: #ffaa00;
          // color: #f40000;
        }

        g,
        path {
          fill: #ffaa00;
          // fill: #f40000;
        }
      }

      &.lista-deseos {
        border-bottom-color: #f40000;

        a {
          // color: #ffaa00;
          color: #f40000;
        }

        g,
        path {
          // fill: #ffaa00;
          fill: #f40000;
        }
      }

      a {
        color: #4caf50;
      }

      g,
      path {
        fill: #4caf50;
      }
    }

    a,
    li {
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: bold;
      color: #283646;
      transition: all 0.3s ease-in-out 0s;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: #00a729;
        opacity: 0.8;
        text-decoration: none;

        &.destacados {
          g,
          path {
            fill: #ffaa00;
          }
        }

        &.lista-deseos {
          g,
          path {
            fill: #f40000;
          }
        }

        &.llamar {
          g,
          path {
            fill: #4764fe;
          }
        }

        &.resena {
          g,
          path {
            fill: #ff2e00;
          }
        }

        g,
        path {
          fill: #4caf50;
          transition: all 0.3s ease-in-out 0s;
        }
      }

      g,
      path {
        &:hover {
          fill: #4caf50;
        }
      }

      span {
        &:first-child {
          margin-right: 15px;
          width: 22px;
        }
      }
    }
  }

  .opcion {
    button {
      padding: 0 0 0 8px;
    }
    svg {
      fill: #283646;
    }

    .promociones-alerta {
      display: none;

      @media (max-width: 1300px) {
        display: block;
      }
    }

    .notificaciones {
      font-size: 20px;
      color: #283646;
      position: relative;
      padding: 8px;
      cursor: pointer;
    }

    .notificacion-circulo {
      font-weight: bold;
      font-size: 10px;
      background: red;
      border-radius: 100%;
      color: #fff;
      top: 5px;
      position: absolute;
      right: 1px;
      text-align: center;
      width: 15px;
      height: 15px;
    }
    ul {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 7px;

        @media (max-width: 640px) {
          padding: 2px;
        }
      }
    }

    .idioma {
      display: none;

      @media (max-width: 1300px) {
        display: inline-block;
        border: 0;
        width: 50px;
        padding: 9px;

        img {
          width: 100%;
        }
      }
    }

    button {
      border: 0px solid #d6d6d6;
      border-radius: 0px;
      background: transparent;
    }
  }
}

.panel-opciones {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -130%;
  // background: #f3f6f9;
  transition: all 0.3s ease-in-out 0s;
  visibility: hidden;
  display: none;
  z-index: 9999;

  svg {
    position: absolute;
    width: 240%;
    left: -27%;
  }

  @media (max-width: 960px) {
    width: 80%;
    height: 64%;
  }

  ul {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 100;
  }

  li {
    list-style: none;
    padding: 16px 0;
  }

  a {
    color: #f3f6f9;
    font-weight: bold;
  }

  &.open {
    right: 0;
    animation: circle1 cubic-bezier(0.4, 0, 1, 1) 0.5s;
    visibility: visible;
    display: flex;
  }

  .circle {
    background: linear-gradient(135deg, #226929, #067010);
    width: 220%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 0 0 0 100%;

    &:nth-child(2) {
      background: linear-gradient(135deg, #3aa344, #067010);
      border-radius: 0 0 0 91%;
      height: 100%;
    }
  }

  #close {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 60px;
    right: 60px;
    z-index: 999;
    cursor: pointer;
  }

  .circleAnim {
    animation: circle2 cubic-bezier(0.4, 0, 1, 1) 0.8s;
  }
  .circleAnim2 {
    animation: circle2 0.4s cubic-bezier(0.4, 0, 1, 1) 0.2s;
  }

  @keyframes circle1 {
    0% {
      transform: scale(0) translateY(0px);
      width: 0;
    }
    85% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes circle2 {
    0% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

// Menu Telefono
.menu-mobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #f3f6f9;
  box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 15px;
  display: none;

  @media (max-width: 1300px) {
    display: block;
  }

  ul {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 0;
    padding: 0;
  }

  svg {
    width: 22px;
  }

  li,
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 9px;
    color: #283646;
    text-decoration: none;

    &:hover {
      color: #00a729;
      opacity: 0.8;
      text-decoration: none;

      g,
      path {
        fill: #4caf50;
        transition: all 0.3s ease-in-out 0s;
      }
    }

    g,
    path {
      &:hover {
        fill: #4caf50;
      }
    }

    span {
      &:first-child {
        padding-bottom: 5px;
      }
    }
  }

  li {
    list-style-type: none;
    margin: 0 5px;
    display: flex;
    align-items: center;
  }

  .active {
    a {
      color: #4caf50;
    }

    g,
    path {
      fill: #4caf50;
    }

    &.destacados {
      border-bottom-color: #ffaa00;

      a {
        color: #ffaa00;
        // color: #f40000;
      }

      g,
      path {
        fill: #ffaa00;
      }
    }

    &.lista-deseos {
      border-bottom-color: #f40000;

      a {
        // color: #ffaa00;
        color: #f40000;
      }

      g,
      path {
        fill: #f40000;
      }
    }

    a {
      color: #4caf50;
    }

    g,
    path {
      fill: #4caf50;
    }
  }
}

.mobile-m {
  @media (max-width: 960px) {
    margin: 25px 0 140px !important;
  }
}

.cesta {
  cursor: pointer;

  @media (max-width: 1285px) {
    display: none;
  }
}
</style>
