<template>
  <img id="logo" src="@/assets/images/logo.png" alt="" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>