<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="llamarCamarero"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="cerrar" data-bs-dismiss="modal">
            <svg
              fill="#545454"
              width="25"
              viewBox="0 0 329.26933 329"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
              ></path>
            </svg>
          </div>
          <div class="row" v-if="!solicitarCuenta" id="camarero-opciones">
            <div class="col-sm-6">
              <div
                @click="solicitarCamarero()"
                data-bs-dismiss="modal"
                class="llamar-container llamar-camarero"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="71"
                    height="71"
                    fill="none"
                    viewBox="0 0 71 71"
                  >
                    <path
                      fill="#4A4F4B"
                      d="M50.028 30.89a18.792 18.792 0 004.331-12.019C54.36 7.424 44.791 0 35.5 0c-9.92 0-18.86 7.874-18.86 18.871 0 4.718 1.628 8.753 4.332 12.02C9.144 32.505 0 42.677 0 54.948v2.146l.69.62A52.014 52.014 0 0033.42 71V52.173h4.16V71a52.015 52.015 0 0032.73-13.286l.69-.62v-2.146c0-12.27-9.144-22.443-20.972-24.057zM14.006 61.8a47.931 47.931 0 01-9.846-6.576v-.276c0-7.346 3.955-13.783 9.846-17.296V61.8zm30.508-10.772L35.5 47.6l-9.014 3.428V39.442L35.5 42.87l9.014-3.428v11.586zM35.5 33.579c-7.551 0-13.79-5.727-14.607-13.069a18.857 18.857 0 008.921 2.246 18.83 18.83 0 0012.465-4.709 18.933 18.933 0 003.652-4.362 18.87 18.87 0 004.24 6.087c-.467 7.692-6.868 13.807-14.671 13.807zm31.34 21.645a47.927 47.927 0 01-9.846 6.576V37.651c5.89 3.513 9.846 9.95 9.846 17.296v.276z"
                    />
                  </svg>
                </div>
                <div class="texto">Llamar al camarero</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="llamar-container solicitar"
                @click="solicitarCuentaHandler()"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="71"
                    height="71"
                    fill="none"
                    viewBox="0 0 71 71"
                  >
                    <path
                      fill="#0FBF00"
                      d="M47.333 61.139c-9.88 0-18.46-5.601-22.74-13.806h22.74v-7.889H22.03c-.198-1.282-.335-2.603-.335-3.944 0-1.341.138-2.663.335-3.944h25.303v-7.89h-22.74c4.28-8.204 12.84-13.805 22.74-13.805 6.37 0 12.189 2.327 16.666 6.173L71 9.072C64.728 3.452 56.425 0 47.333 0 31.891 0 18.776 9.88 13.904 23.667H0v7.889h12.07a32.605 32.605 0 00-.237 3.944c0 1.341.08 2.643.237 3.944H0v7.89h13.904C18.776 61.118 31.891 71 47.334 71c9.091 0 17.394-3.451 23.666-9.072l-7.002-6.982c-4.476 3.846-10.294 6.193-16.665 6.193z"
                    />
                  </svg>
                </div>
                <div class="texto">Solicitar Cuenta</div>
              </div>
            </div>
          </div>
          <div class="row" v-if="solicitarCuenta" id="camarero-pago">
            <div class="col-sm-6">
              <div
                class="llamar-container pagar-camarero"
                @click="showSolicitarCuenta(carrito)"
                data-bs-dismiss="modal"
              >
                <div>
                  <svg
                    version="1.1"
                    width="71px"
                    height="71px"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 480.718 480.718"
                    style="enable-background: new 0 0 480.718 480.718"
                    xml:space="preserve"
                  >
                    <g>
                      <g>
                        <g>
                          <path
                            style="fill: #3bb54a"
                            d="M471.804,181.845v180.767c-0.285,15.954-13.301,28.737-29.257,28.735H97.73
                      c-15.87,0-28.735-12.865-28.735-28.735V328.13h313.992c15.954-0.285,28.737-13.301,28.735-29.257V152.588h30.824
                      C458.705,152.588,471.804,165.686,471.804,181.845z"
                          />
                          <path
                            style="fill: #3bb54a"
                            d="M411.722,152.588v146.286c0.003,15.956-12.781,28.972-28.735,29.257H38.171
                      c-16.158,0-29.257-13.099-29.257-29.257V118.106c0.285-15.954,13.301-28.737,29.257-28.735h344.816
                      c15.87,0,28.735,12.865,28.735,28.735V152.588z M380.375,275.886c0-11.542-9.356-20.898-20.898-20.898
                      c-11.542,0-20.898,9.356-20.898,20.898s9.356,20.898,20.898,20.898C371.019,296.784,380.375,287.427,380.375,275.886
                      L380.375,275.886L380.375,275.886z M303.575,208.49c-0.001-51.649-41.871-93.518-93.52-93.517s-93.518,41.871-93.517,93.52
                      c0.001,51.649,41.871,93.518,93.52,93.517c0.174,0,0.347,0,0.521-0.001c51.36,0.001,92.997-41.634,92.997-92.994
                      C303.577,208.839,303.576,208.664,303.575,208.49z M82.579,140.049c0-11.542-9.356-20.898-20.898-20.898
                      s-20.898,9.356-20.898,20.898s9.356,20.898,20.898,20.898S82.579,151.59,82.579,140.049z"
                          />
                        </g>
                        <g>
                          <circle
                            style="fill: #87e680"
                            cx="359.477"
                            cy="275.886"
                            r="20.898"
                          />
                          <circle
                            style="fill: #87e680"
                            cx="210.579"
                            cy="208.49"
                            r="92.996"
                          />
                          <circle
                            style="fill: #87e680"
                            cx="61.681"
                            cy="140.049"
                            r="20.898"
                          />
                        </g>
                      </g>
                      <g>
                        <path
                          style="fill: #083863"
                          d="M210.579,312.457c-57.419,0.289-104.2-46.025-104.488-103.444s46.025-104.2,103.444-104.488
                    c57.419-0.289,104.2,46.025,104.488,103.444c0.001,0.174,0.001,0.347,0.001,0.521
                    C314.025,265.706,267.795,312.169,210.579,312.457z M210.579,125.943c-45.877-0.288-83.301,36.67-83.589,82.547
                    s36.67,83.301,82.547,83.589c45.877,0.288,83.301-36.67,83.589-82.547c0.002-0.347,0.002-0.694,0-1.042
                    C292.841,163.019,256.05,126.228,210.579,125.943z"
                        />
                        <path
                          style="fill: #083863"
                          d="M442.547,141.094h-19.331v-22.988c-0.56-21.896-18.327-39.432-40.229-39.706H38.171
                    c-21.35,0.281-38.43,17.816-38.15,39.166c0.002,0.18,0.006,0.36,0.011,0.54v180.767c-0.873,21.623,15.948,39.859,37.571,40.732
                    c0.189,0.008,0.378,0.014,0.567,0.019h19.331v22.988c0.56,21.896,18.327,39.432,40.229,39.706h344.816
                    c21.35-0.281,38.43-17.816,38.15-39.166c-0.002-0.18-0.006-0.36-0.011-0.54V181.845c0.873-21.623-15.948-39.859-37.571-40.732
                    C442.925,141.105,442.736,141.099,442.547,141.094z M20.93,298.873V118.106c-0.581-9.793,6.887-18.203,16.68-18.784
                    c0.187-0.011,0.373-0.019,0.56-0.024h344.816c10.367,0.259,18.788,8.452,19.331,18.808v180.767
                    c-0.259,10.655-8.686,19.31-19.331,19.853H38.171C27.722,318.726,20.93,309.322,20.93,298.873z M459.788,362.612
                    c0.581,9.793-6.887,18.203-16.68,18.784c-0.187,0.011-0.374,0.019-0.56,0.024H97.73c-10.367-0.259-18.788-8.452-19.331-18.808
                    v-22.988h304.588c22.184-0.56,39.955-18.561,40.229-40.751V161.992h19.331c10.449,0,17.241,9.404,17.241,19.853V362.612z"
                        />
                        <path
                          style="fill: #083863"
                          d="M61.681,171.396c-17.312,0-31.347-14.035-31.347-31.347c0-17.312,14.034-31.347,31.347-31.347
                    s31.347,14.034,31.347,31.347l0,0C93.028,157.361,78.994,171.396,61.681,171.396z M61.681,129.6
                    c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449s10.449-4.678,10.449-10.449l0,0
                    C72.13,134.278,67.452,129.6,61.681,129.6z"
                        />
                        <path
                          style="fill: #083863"
                          d="M359.477,307.232c-17.312,0-31.347-14.035-31.347-31.347s14.035-31.347,31.347-31.347
                    c17.312,0,31.347,14.034,31.347,31.347l0,0C390.824,293.198,376.79,307.232,359.477,307.232z M359.477,265.437
                    c-5.771,0-10.449,4.678-10.449,10.449c0,5.771,4.678,10.449,10.449,10.449c5.771,0,10.449-4.678,10.449-10.449
                    C369.926,270.115,365.248,265.437,359.477,265.437z"
                        />
                        <path
                          style="fill: #083863"
                          d="M236.179,249.241c-15.934,6.152-33.999,2.251-45.976-9.927l-3.657-4.18h43.363
                    c5.771,0,10.449-4.678,10.449-10.449s-4.678-10.449-10.449-10.449h-52.245c0-1.567-0.522-3.657-0.522-5.225h52.767
                    c5.771,0,10.449-4.678,10.449-10.449c0-5.771-4.678-10.449-10.449-10.449h-47.543c2.141-3.813,4.776-7.326,7.837-10.449
                    c12.093-11.996,30.144-15.688,45.976-9.404c5.482,2.164,11.681-0.526,13.845-6.008c2.164-5.482-0.526-11.681-6.008-13.845
                    c-23.882-8.881-50.744-3.183-68.963,14.629c-6.788,7.177-11.955,15.73-15.151,25.078h-8.359c-5.771,0-10.449,4.678-10.449,10.449
                    c0,5.771,4.678,10.449,10.449,10.449h4.702c0,1.567,0.522,3.657,0.522,5.225h-5.224c-5.771,0-10.449,4.678-10.449,10.449
                    s4.678,10.449,10.449,10.449h10.449c3.249,6.969,7.667,13.33,13.061,18.808c12.024,12.099,28.396,18.873,45.453,18.808
                    c8.033,0.139,16.017-1.28,23.51-4.18c5.205-2.492,7.405-8.731,4.914-13.936C246.651,249.875,241.182,247.561,236.179,249.241
                    L236.179,249.241z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="texto">En Efectivo</div>
              </div>
            </div>
            <div class="col-sm-6">
              <div
                class="llamar-container pagar-camarero"
                @click="showSolicitarCuenta(carrito)"
                data-bs-dismiss="modal"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    fill="rgb(74, 79, 75)"
                    viewBox="0 0 875 875"
                    width="71px"
                    height="71px"
                  >
                    <g id="surface1">
                      <path
                        d="M 454.171875 370.855469 C 424.148438 337.378906 426.09375 286.128906 458.554688 255.007812 C 491.023438 223.90625 542.324219 224.148438 574.488281 255.578125 L 717.609375 398.71875 C 718.222656 396.054688 718.601562 393.351562 718.75 390.625 L 718.75 146.59375 L 718.601562 146.234375 L 718.75 146.234375 L 718.75 46.875 C 718.75 20.992188 697.757812 0 671.875 0 L 46.875 0 C 20.992188 0 0 20.992188 0 46.875 L 0 390.625 C 0 416.507812 20.992188 437.5 46.875 437.5 L 515.265625 437.5 Z M 625 46.875 L 656.25 46.875 L 656.25 93.75 L 625 93.75 Z M 562.5 46.875 L 593.75 46.875 L 593.75 93.75 L 562.5 93.75 Z M 500 46.875 L 531.25 46.875 L 531.25 93.75 L 500 93.75 Z M 437.5 46.875 L 468.75 46.875 L 468.75 93.75 L 437.5 93.75 Z M 31.25 146.875 L 31.25 71.875 C 31.25 49.4375 49.4375 31.25 71.875 31.25 L 146.875 31.25 C 169.3125 31.25 187.5 49.4375 187.5 71.875 L 187.5 146.875 C 187.5 169.3125 169.3125 187.5 146.875 187.5 L 71.875 187.5 C 49.4375 187.5 31.25 169.3125 31.25 146.875 Z M 109.375 359.375 L 46.875 359.375 L 46.875 328.125 L 109.375 328.125 Z M 109.375 265.625 L 46.875 265.625 L 46.875 234.375 L 109.375 234.375 Z M 203.125 265.625 L 140.625 265.625 L 140.625 234.375 L 203.125 234.375 Z M 296.875 265.625 L 234.375 265.625 L 234.375 234.375 L 296.875 234.375 Z M 390.625 359.375 L 328.125 359.375 L 328.125 328.125 L 390.625 328.125 Z M 390.625 265.625 L 328.125 265.625 L 328.125 234.375 L 390.625 234.375 Z M 390.625 265.625 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 72.625 468.75 L 89.109375 511.527344 L 200.171875 468.75 Z M 72.625 468.75 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 146.875 156.25 C 152.054688 156.25 156.25 152.050781 156.25 146.875 L 156.25 125 L 125 125 L 125 93.75 L 156.25 93.75 L 156.25 71.875 C 156.25 66.699219 152.054688 62.5 146.875 62.5 L 71.875 62.5 C 66.699219 62.5 62.5 66.699219 62.5 71.875 L 62.5 93.75 L 93.75 93.75 L 93.75 125 L 62.5 125 L 62.5 146.875 C 62.5 152.050781 66.699219 156.25 71.875 156.25 Z M 146.875 156.25 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 796.53125 386.6875 C 801.632812 375.3125 801.949219 362.363281 797.421875 350.757812 L 750 227.726562 L 750 390.625 C 749.976562 401.375 747.734375 412.023438 743.40625 421.875 L 770.511719 411.425781 C 782.140625 406.984375 791.507812 398.078125 796.53125 386.6875 Z M 796.53125 386.6875 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 824.3125 252.96875 L 773.6875 202.246094 L 826.5625 339.574219 C 842.109375 379.859375 822.046875 425.109375 781.742188 440.625 L 765.71875 446.875 L 823.535156 504.6875 L 801.441406 526.78125 L 552.464844 277.6875 C 532.417969 257.714844 500.09375 257.390625 479.644531 276.972656 C 459.203125 296.539062 458.128906 328.859375 477.222656 349.746094 L 605.347656 489.445312 C 609.929688 494.445312 610.765625 501.855469 607.390625 507.753906 C 571.710938 570.097656 573.234375 647.027344 611.347656 707.921875 L 618.125 718.75 L 875 718.75 L 875 374.863281 C 874.875 329.125 856.648438 285.308594 824.3125 252.96875 Z M 824.3125 252.96875 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 671.875 750 L 625 750 L 625 875 L 875 875 L 875 750 Z M 687.5 812.5 L 656.25 812.5 L 656.25 781.25 L 687.5 781.25 Z M 687.5 812.5 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                      <path
                        d="M 286.828125 468.75 L 286.828125 468.84375 L 100.34375 540.625 L 126.703125 609.105469 C 136.011719 633.273438 163.140625 645.3125 187.296875 635.996094 L 564.203125 490.828125 L 543.90625 468.75 Z M 286.828125 468.75 "
                        style="
                          stroke: none;
                          fill-rule: nonzero;
                          fill: rgb(0%, 0%, 0%);
                          fill-opacity: 1;
                        "
                      />
                    </g>
                  </svg>
                </div>
                <div class="texto">Pagar con tarjeta</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import * as bootstrap from "bootstrap";

export default {
  setup() {
    const store = useStore();
    const dialog = inject("$swal");
    const solicitarCuenta = ref(false);

    onMounted(() => {
      let myModal = new bootstrap.Modal(
        document.getElementById("llamarCamarero"),
        {
          backdrop: "true",
        }
      );
      myModal.show();

      document
        .getElementById("llamarCamarero")
        .addEventListener("hidden.bs.modal", function (event) {
          backdrop();
        });
    });

    const showSolicitarCuenta = (carrito) => {
      if (carrito.length === 0) {
        dialog({
          position: "center",
          icon: "error",
          title: "No tienes ningun pedido pendiente",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        dialog({
          position: "center",
          icon: "success",
          title: "Tu cuenta llegara a tu mesa en un momento",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    };

    const solicitarCamarero = () => {
      dialog({
        position: "center",
        icon: "success",
        title: "El camarero a sido llamado",
        showConfirmButton: false,
        timer: 2000,
      });
    };

    const solicitarCuentaHandler = () => {
      solicitarCuenta.value = true;
    };

    const carrito = computed(() => store.state.carrito.carrito);

    const backdrop = () => {
      store.commit("menu/mostrarCamarero", false);
    };

    return {
      solicitarCuenta,
      solicitarCuentaHandler,
      showSolicitarCuenta,
      solicitarCamarero,
      carrito,
    };
  },
};
</script>

<style lang="scss" scoped>
.llamar-container {
  background: #f5f5f5;
  border-radius: 12px;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 25px;
  transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
  cursor: pointer;
  text-align: center;

  @media (max-width: 600px) {
    margin-bottom: 25px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 30px 60px;
    transform: scale(1.05, 1.05);
  }

  .texto {
    margin-top: 25px;
    font-size: 18px;
    font-weight: bold;
  }
}

#camarero-opciones {
  display: flex;
}

.cerrar {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 12px;
}

.modal-body {
  padding: 60px 20px;
}
</style>