<template>
  <div class="card">
    <router-link :to="{ name: 'Productos', params: { id: categoria.id } }">
      <!-- categoria.id -->
      <div
        class="imagen"
        :style="{
          backgroundImage:
            'url(' + require(`@/assets/images/no-imagen.jpg`) + ')',
        }"
        v-if="categoria.logo === 'null'"
      ></div>
      <div
        v-else
        class="imagen"
        :style="{
          backgroundImage: `url(https://${restaurante.path}/uploads/images/categoria_productos/${restaurante.eid}/${restaurante.id}/${categoria.logo})`,
        }"
      ></div>
      <div class="contenido-card">
        {{ categoria.nombre }}
      </div>
    </router-link>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "Card",
  props: ["categoria"],

  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);

    return { restaurante };
  },
};
</script>

<style lang="scss">
.card-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 25px 0;

  .card {
    width: 14.666667%;
    background: #ffffff;
    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    //   0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);
    border: 0;
    border-radius: 8px;
    margin: 10px;
    transition: all 1s cubic-bezier(0.2, 0.8, 0.2, 1) 0s;
    cursor: pointer;

    a {
      color: #283646;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    @media (max-width: 1280px) {
      width: 17.766667%;
    }

    @media (max-width: 1024px) {
      width: 21.966667%;
    }

    @media (max-width: 698px) {
      width: 27.73%;
      margin: 5px;
    }

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.35) 0px 30px 60px;
      transform: scale(1.05, 1.05);
    }
  }

  .imagen {
    width: 100%;
    height: 200px;
    background-color: #ccc;
    border-radius: 8px 8px 0 0;
    background-size: cover;
    background-position: center;

    @media (max-width: 698px) {
      height: 100px;
    }
  }

  .contenido-card {
    text-align: left;
    font-weight: bold;
    padding: 10px;
    font-size: 14px;

    @media (max-width: 698px) {
      font-size: 9px;
    }
  }
}
</style>
