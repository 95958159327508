<template>
  <Loading v-if="loading" />
  <Error v-if="error" />

  <template v-if="ready">
    <Menu />
    <Notificaciones />
    <Busqueda />
    <CategoriasNavs />
    <Carrito />
    <Camarero v-if="llamarCamarero" />
    <Resena v-if="resena" />
    <Sidebar />

    <slot />

    <MenuFooter />
  </template>
</template>

<script>
import Menu from "@/components/menu/Menu.vue";
import MenuFooter from "@/components/menu/FooterMenu.vue";
import Carrito from "@/components/carrito/Carrito.vue";
import Busqueda from "@/components/buscador/Buscador.vue";
import CategoriasNavs from "@/components/categoriasNav/CategoriasNav";
import Camarero from "@/components/camarero/Camarero";
import Sidebar from "@/components/sidebar/Sidebar";
import Resena from "@/components/resena/Resena.vue";
import Notificaciones from "@/components/notificaciones/Notificaciones.vue";
import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted } from "@vue/runtime-core";

export default {
  components: {
    Menu,
    MenuFooter,
    Carrito,
    Resena,
    CategoriasNavs,
    Busqueda,
    Camarero,
    Sidebar,
    Notificaciones,
    Loading,
    Error,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const params = route.params;
    const loading = computed(() => store.state.loading);
    const ready = computed(() => store.state.ready);
    const error = computed(() => store.state.error.error);

    const llamarCamarero = computed(() => store.state.menu.llamarCamarero);
    const resena = computed(() => store.state.menu.resena);

    // onMounted(async () => {
    //   const response = await getProducts(20);
    //   products.value = response;
    // });

    onMounted(() => {
      store.dispatch("cargandoInformacion", params);
    });

    return { llamarCamarero, resena, loading, ready, error };
  },
};
</script>

<style>
</style>