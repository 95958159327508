export const menu = {
    namespaced: true,
    state: {
        llamarCamarero: false,
        mostrarNotificaciones: false,
        sideMenu:false,
        mostrarIdiomas: false,
        mostrarSidebar: false,
        resena: false
    },
    actions: {
      
    },
    mutations: {
      mostrarCamarero(state, payload) {
          state.llamarCamarero = payload
      },
      mostrarResena(state, payload) {
          state.resena = payload
      },
      mostrarIdiomas(state, payload) {
          state.mostrarIdiomas = payload
      },
      mostrarSidebar(state, payload) {
          state.mostrarSidebar = payload
      },
      mostrarNotificaciones(state, payload) {
          state.mostrarNotificaciones = payload
      }
    },
  };
  